import { Link, useParams, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import FavoriteBorderRoundedIcon from "@mui/icons-material/FavoriteBorderRounded";
import FavoriteIcon from "@mui/icons-material/Favorite";
import axios from "axios";

import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Modal from "react-modal";

import {
  BedOutlined,
  CropSquareOutlined,
  GarageOutlined,
  ShowerOutlined,
} from "@mui/icons-material";
import avatar from "../assets/images/avatar.png";


function PropertyDetails2() {
  const { id } = useParams();
  const navigate = useNavigate(); // For redirecting the user

  const [property, setProperty] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const backendUrl2 = process.env.REACT_APP_BACKEND_URL2;

  const [userId, setUserId] = useState("");
  const [isFavorite, setIsFavorite] = useState(false); // For favorite status

  // const [globalPrice, setGlobalPrice] = useState(0); // Global price state
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal state

  useEffect(() => {
    const userId = localStorage.getItem("userId");
    if (userId) {
      setUserId(userId);
    }
  }, []);

  // const [email, setEmail] = useState("");

  // useEffect(() => {
  //   const email = localStorage.getItem("email");
  //   if (email) {
  //     setEmail(email);
  //   }
  // }, []);

  useEffect(() => {
    const fetchProperty = async () => {
      try {
        const response = await fetch(`${backendUrl}/houses/${id}`);
        if (!response.ok) {
          throw new Error("Property not found");
        }
        const data = await response.json();
        setProperty(data);
        // setGlobalPrice(data.price); // Set global price when property is fetched
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchProperty();
  }, [id, backendUrl]);

  // Check if the property is already in the user's favorites
  useEffect(() => {
    const checkFavoriteStatus = async () => {
      try {
        const response = await axios.get(
          `${backendUrl}/houses/favorites/${userId}/${id}`
        );
        setIsFavorite(response.data.isFavorite);
      } catch (error) {
        console.error("Error checking favorite status:", error);
      }
    };

    if (userId) {
      checkFavoriteStatus();
    }
  }, [userId, id, backendUrl]);

  // Handle adding/removing from favorites
  const toggleFavorite = async () => {
    try {
      if (isFavorite) {
        // If already favorite, remove it
        await axios.delete(`${backendUrl}/houses/favorites`, {
          data: { userId, propertyId: id },
        });
        setIsFavorite(false);
      } else {
        // Otherwise, add to favorites
        await axios.post(`${backendUrl}/houses/favorites`, {
          userId,
          propertyId: id,
        });
        setIsFavorite(true);
      }
    } catch (error) {
      console.error("Error updating favorite status:", error);
    }
  };

  const handleSendMessage = () => {
    if (property && property.user_id) {
      navigate(`/chats/${userId}/${property.user_id}`);
    } else {
      console.error("Owner ID not found");
    }
  };

  // // Paystack public key from your Paystack dashboard
  // const publicKey = "sk_test_08c411ce780bdeb96b4526f53c29168edf198c4c";

  // // Configuration for Paystack
  // const config = {
  //   reference: new Date().getTime().toString(),
  //   email: email || "olakunle09140@gmail.com", // Replace with actual user email
  //   amount: globalPrice * 100, // Use global price for the amount (in kobo)
  //   publicKey: publicKey,
  // };
  // // Initialize the Paystack payment
  // const initializePayment = usePaystackPayment(config);

  // const onSuccess = async (reference) => {
  //   // Handle payment success
  //   console.log("Payment successful!", reference);

  //   // Make a request to the backend to add the purchase to the purchased table
  //   try {
  //     const response = await fetch(`${backendUrl}/houses/purchases`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         property_id: id, // Send the property id
  //         user_id: userId, // Send the user id
  //         reference: reference.reference, // Include the payment reference
  //       }),
  //     });

  //     if (response.ok) {
  //       console.log("Purchase recorded successfully in backend");
  //       // Display modal upon successful backend response
  //       setIsModalOpen(true);
  //     } else {
  //       console.error("Failed to record purchase in backend");
  //     }
  //   } catch (error) {
  //     console.error("Error:", error);
  //   }
  // };

  // const onClose = () => {
  //   // Handle what happens when the modal is closed
  //   console.log("Payment closed");
  // };

  const closeModal = () => {
    setIsModalOpen(false);
    // Optional: Redirect user after closing modal
    navigate("/home"); // Redirect to a thank you page or wherever you want
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <>
      <head>
        <title>HousemeNG | Property Details</title>
      </head>
      <div className="mt-[111px]">
        <div className="mx-[5.5%]">
          <div className="mb-5 flex items-center">
            <Link to="/buy">
              <button className=" bg-[#d0f4d8] rounded-lg flex py-2 px-3 space-x-3">
                <KeyboardArrowLeftOutlinedIcon className="mt-[1px]" />
                <p className="font-halant font-semibold text-lg">Back to Map</p>
              </button>
            </Link>
          </div>
        </div>
        {/* Header */}
        <div className="px-[5.5%] mb-7 flex items-center justify-between">
          <div>
            <h1 className="sm:text-2xl font-halant font-bold text-lg">
              {property.house_name}
            </h1>
            <p className="sm:text-lg font-halant text-sm">
              {property.location}, {property.state}
            </p>
          </div>
          <button
            className="bg-green-700 text-white px-4 py-2 rounded-md flex space-x-3 items-center"
            onClick={toggleFavorite}
          >
            {isFavorite ? <FavoriteIcon /> : <FavoriteBorderRoundedIcon />}
            <p className="hidden font-halant text-lg sm:flex">
              {isFavorite ? "Remove from Favorites" : "Add to Favorites"}
            </p>
          </button>
        </div>
        {/* Images */}
        <div className="flex px-[15%] bg-cover w-full h-auto">
          <div className="flex flex-col">
            {property.images &&
              property.images.slice(0, 2).map((image, index) => (
                <div key={index} className="mr-3 mb-3">
                  <img
                    src={`${backendUrl2}/${image}`}
                    alt={property.house_name}
                    className="rounded-lg shadow-md"
                  />
                </div>
              ))}
          </div>
          <div className="flex flex-col">
            {property.images &&
              property.images.slice(2, 4).map((image, index) => (
                <div key={index} className="mb-3">
                  <img
                    src={`${backendUrl2}/${image}`}
                    alt={property.house_name}
                    className="rounded-lg"
                  />
                </div>
              ))}
          </div>
        </div>
        {/* Main Content */}
        <div className="flex flex-col lg:flex-row px-[5.5%] py-4 md:space-x-5 sm:space-x-0">
          <div className="lg:w-[66%] sm:w-[100%]">
            {/* Property Details */}
            <div>
              <div className="flex items-center mb-2 justify-between py-5 sm:px-7 px-2 border border-gray-200 shadow-sm rounded-lg">
                <div>
                  <h1 className="text-gray-600 hidden sms:flex font-halant-light text-lg">
                    Bedrooms
                  </h1>
                  <p className="text-gray-600 text-center sm:text-lg text-sm font-halant font-bold">
                    <BedOutlined className="text-green-700" /> {property.beds}
                  </p>
                </div>
                <div>
                  <h1 className="text-gray-600 hidden sms:flex font-halant-light text-lg">
                    Bathrooms
                  </h1>
                  <p className="text-gray-600 text-center sm:text-lg text-sm font-halant font-bold">
                    <ShowerOutlined className="text-green-700" />{" "}
                    {property.baths}
                  </p>
                </div>
                <div>
                  <h1 className="text-gray-600 hidden sms:flex font-halant-light text-lg">
                    Garages
                  </h1>
                  <p className="text-gray-600 text-center sm:text-lg text-sm font-halant font-bold">
                    <GarageOutlined className="text-green-700" />{" "}
                    {property.garages}
                  </p>
                </div>
                <div>
                  <h1 className="text-gray-600 hidden sms:flex font-halant-light text-lg">
                    Land Area
                  </h1>
                  <p className="text-gray-600 text-center sm:text-lg text-sm font-halant font-bold">
                    <CropSquareOutlined className="text-green-700" />{" "}
                    {property.land_area} sqft
                  </p>
                </div>
                <div>
                  <h1 className="text-gray-600 hidden sms:flex font-halant-light text-lg">
                    Tag
                  </h1>
                  <p className="text-gray-600 text-center sm:text-lg text-sm font-halant font-bold">
                    <CheckBoxOutlinedIcon className="text-green-700" />{" "}
                    {property.tag}
                  </p>
                </div>
              </div>
              {/* about property */}
              <div className="mb-4">
                <h2 className="text-xl sm:text-2xl font-halant font-semibold mb-2">
                  About this property
                </h2>
                <p className="text-gray-700 font-halant-light md:text-lg">
                  {property.description}
                </p>
              </div>
            </div>
          </div>
          {/* price */}
          <div className="px-7 py-5 border border-gray-200 rounded-lg lg:w-[33%] sm:w-[100%]">
            <div className="mb-4 items-center">
              <h1 className="text-gray-600 text-center font-halant font-semibold text-xl lg:text-start">
                Sale Price
              </h1>
              <div className="lg:flex block">
                <p className="text-3xl font-halant font-bold text-green-700 text-center lg:text-start">
                  N{parseInt(property.price).toLocaleString()}
                </p>
                <p className="text-gray-500 font-halant-light text-lg px-2 text-center">
                  (one time payment)
                </p>
              </div>
            </div>
            {/* Buy Now Button
            <div className="text-center">
              <button
                className="bg-green-700 text-white py-3 rounded-full px-[25%] font-halant font-semibold"
                onClick={() => {
                  setGlobalPrice(parseInt(property.price)); // Set global price when button is clicked
                  initializePayment(onSuccess, onClose);
                }}
              >
                Buy Now
              </button>
            </div> */}
            <div className="border border-gray-200 mt-5"></div>

            {/* Owner Details */}
            <div className="mt-5">
              <h1 className="text-gray-600 font-semibold font-halant text-xl">
                Listed by {property.poster_name}
              </h1>
            </div>
            <div className="mt-4 flex items-center justify-between">
              <div className="flex items-center">
                <img
                  src={avatar}
                  alt="Owner Avatar"
                  className="w-12 h-12 rounded-full mr-4"
                />
                <div className="">
                  <h3 className="text-md lg:text-lg font-halant font-semibold">
                    {property.lister_title}
                  </h3>
                  <p className="text-gray-500 font-halant-light">
                    Property Owner
                  </p>
                </div>
              </div>
              {/* more info */}
              <div>
                <button className="bg-[#d0f4d8] text-black font-semibold px-3 py-2 rounded-md text-sm flex space-x-1">
                  <InfoOutlinedIcon />
                  <span className="hidden lg:flex font-halant-bold text-lg">
                    More Info
                  </span>
                </button>
              </div>
            </div>
            {/* Buy Now Button */}
            <div className="text-center mt-5">
              <button
                className="bg-green-700 text-white py-3 rounded-full px-[20%] font-halant text-lg font-semibold"
                onClick={handleSendMessage} // Add onClick handler
              >
                Send a Message
              </button>
            </div>
          </div>
        </div>
        <div className="border-b mb-10 mt-10"></div>
      </div>

      {/* Success Modal */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Purchase Successful"
        className="modal-class"
        overlayClassName="modal-overlay-class"
      >
        <div className="modal-content">
          <h2>Purchase Successful</h2>
          <p>Your transaction has been completed successfully.</p>
          <button
            onClick={closeModal}
            className="bg-green-700 text-white py-2 px-4 rounded-md"
          >
            Close
          </button>
        </div>
      </Modal>
    </>
  );
}

export default PropertyDetails2;
