import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import SearchSharpIcon from "@mui/icons-material/SearchSharp";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import { FaRegSadCry, FaHome } from "react-icons/fa"; // Import necessary icons
import Properties from "./Properties";

function Warehouse() {
  const [propertyData, setPropertyData] = useState([]);
  const [featuredProperties, setFeaturedProperties] = useState([]); // State for Featured Properties
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // State variables for filters
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedPriceRange, setSelectedPriceRange] = useState("");

  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const type = "Warehouse";

  // Ref for Search Results (Optional for Smooth Scrolling)
  const searchResultsRef = useRef(null);

  // State Variable for Search Trigger
  const [isSearched, setIsSearched] = useState(false);

  useEffect(() => {
    const fetchPropertyData = async () => {
      try {
        const response = await axios.get(
          `${backendUrl}/houses/properties/${type}`
        );
        setPropertyData(response.data);
        // Extract Featured Properties
        const featured = response.data.filter((property) => property.featured);
        setFeaturedProperties(featured);
      } catch (error) {
        console.error("Error fetching property data:", error);
        setError("Failed to load properties. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchPropertyData();
  }, [backendUrl, type]);

  // Effect to Reset `isSearched` When Inputs Are Cleared
  useEffect(() => {
    if (selectedLocation.trim() === "" && selectedPriceRange.trim() === "") {
      setIsSearched(false);
    }
  }, [selectedLocation, selectedPriceRange]);

  if (loading)
    return (
      <div className="flex items-center justify-center h-screen">
        <p className="text-gray-600 text-xl">Loading...</p>
      </div>
    );

  // Filter properties based on selected filters
  const filteredProperties = propertyData.filter((property) => {
    // Filter by location (state)
    const trimmedLocation = selectedLocation.trim().toLowerCase();
    const locationMatch = trimmedLocation
      ? property.state?.toLowerCase().includes(trimmedLocation)
      : true;

    // Filter by price range
    let priceMatch = true;
    if (selectedPriceRange) {
      const price = parseFloat(property.price);
      if (selectedPriceRange === "100k to 200k") {
        priceMatch = price >= 100000 && price <= 200000;
      } else if (selectedPriceRange === "200k to 300k") {
        priceMatch = price >= 200000 && price <= 300000;
      }
    }

    // Return true if all conditions match
    return locationMatch && priceMatch;
  });

  // Determine if Search Inputs are Provided
  const isSearchInputProvided =
    selectedLocation.trim() !== "" || selectedPriceRange.trim() !== "";

  // Available Properties: Exclude Featured and Filtered Properties
  const availableProperties = propertyData.filter(
    (property) =>
      !property.featured &&
      (!isSearched || !filteredProperties.find((fp) => fp.id === property.id))
  );

  return (
    <>
      <head>
        <title>HousemeNG | Warehouse</title>
      </head>
      <div className="flex flex-col md:flex-row mt-[80px] mb-11">
        {/* Properties Section */}
        <div className="w-full md:z-0 z-[1001] md:mx-[2%]">
          <div className="mt-7">
            <div className="mx-7 md:mx-0 bg-white md:bg-none pt-5 px-5 md:pt-0 md:px-0 rounded-3xl">
              <div className="mb-5">
                <h1 className="sm:text-3xl font-halant text-xl font-bold">
                  Search Warehouses
                </h1>
                <h2 className="text-gray-500 font-halant font-semibold text-sm sm:text-base">
                  {propertyData.length} warehouses available
                </h2>
              </div>
              <div className="border border-gray-200 md:hidden"></div>
              <div className="bg-white rounded-lg p-3 flex flex-col sm:flex-row items-center space-x-3 space-y-3 sm:space-y-0">
                <div className="flex space-x-3">
                  <div className="relative flex items-center">
                    <LocationOnOutlinedIcon className="text-gray-500 absolute ml-2" />
                    <input
                      type="text"
                      placeholder="Location"
                      value={selectedLocation}
                      onChange={(e) => setSelectedLocation(e.target.value)}
                      className="pl-8 px-4 py-2 font-manrope font-semibold border-2 rounded-md bg-white text-black border-gray-300 w-[130px] focus:outline-none focus:ring-2 focus:ring-green-700 focus:border-transparent"
                    />
                  </div>
                  <select
                    value={selectedPriceRange}
                    onChange={(e) => setSelectedPriceRange(e.target.value)}
                    name="priceRange"
                    id="priceRange"
                    placeholder="Price Range"
                    className="px-4 py-2 border-2 font-manrope font-semibold rounded-md bg-white text-gray-500 border-gray-300 w-[130px] focus:outline-none focus:ring-2 focus:ring-green-700 focus:border-transparent"
                  >
                    <option value="">Price Range</option>
                    <option value="100k to 200k">100k to 200k</option>
                    <option value="200k to 300k">200k to 300k</option>
                  </select>
                </div>

                <div className="flex space-x-3">
                  <div className="flex">
                    <div className="relative">
                      <button
                        onClick={() => {
                          if (isSearchInputProvided) {
                            setIsSearched(true);
                            if (searchResultsRef.current) {
                              searchResultsRef.current.scrollIntoView({
                                behavior: "smooth",
                              });
                            }
                          }
                        }}
                        disabled={!isSearchInputProvided} // Disable if no input
                        className={`bg-green-700 text-white text-lg px-6 py-2 font-halant font-semibold rounded-3xl pr-10 flex items-center ${
                          !isSearchInputProvided
                            ? "opacity-50 cursor-not-allowed"
                            : "hover:bg-green-800"
                        }`}
                        aria-label="Search warehouses"
                      >
                        Search
                        <SearchSharpIcon className="text-white absolute right-3 top-1/2 transform -translate-y-1/2 mt-1" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="border border-gray-200 mb-5 md:hidden"></div>
            </div>

            {/* Search Output Section */}
            <div ref={searchResultsRef} className="mx-7 md:mx-0">
              {isSearched && (
                <>
                  {filteredProperties.length > 0 ? (
                    <div className="mb-8">
                      <h2 className="text-2xl font-halant font-bold mb-4">
                        We found some warehouses for you
                      </h2>
                      <Properties
                        properties={filteredProperties}
                        origin="warehouse"
                      />
                    </div>
                  ) : (
                    <div className="flex flex-col items-center justify-center mb-8">
                      <FaRegSadCry size={120} color="gray" aria-hidden="true" />
                      <h2 className="text-gray-600 text-2xl mt-4">
                        We currently don't have what you're looking for, but you
                        can check these out:
                      </h2>
                    </div>
                  )}
                </>
              )}
            </div>

            {/* Featured Properties Section */}
            <div className="mx-7 md:mx-0">
              <div className="mt-8">
                <h2 className="text-xl font-halant font-bold mb-1">
                  Featured Warehouses
                </h2>
                <h2 className="text-lg font-halant-light mb-5">
                  Premium listings highlighted for you
                </h2>
                {featuredProperties.length > 0 ? (
                  <Properties
                    properties={featuredProperties}
                    origin="warehouse"
                  />
                ) : (
                  <p className="text-gray-500">
                    No featured warehouses available.
                  </p>
                )}
              </div>
            </div>

            {/* Available Properties Section */}
            <div className="mx-7 md:mx-0">
              <div className="mt-8">
                <h2 className="text-xl font-halant font-bold mb-1">
                  Available Warehouses
                </h2>
                <h2 className="text-lg font-halant-light mb-5">
                  Browse through our available listings
                </h2>
                {availableProperties.length > 0 ? (
                  <Properties
                    properties={availableProperties}
                    origin="warehouse"
                  />
                ) : (
                  <p className="text-gray-500">
                    No available warehouses to display.
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Warehouse;
