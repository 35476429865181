import React from "react";
import { motion } from "framer-motion";
import { FaCheckCircle } from "react-icons/fa";

const EmailVerified = () => {
  return (
    <motion.div
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8, ease: "easeOut" }}
      className="mt-40 px-6 font-halant flex flex-col items-center min-h-screen"
    >
      <motion.div
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{ duration: 0.5, ease: "backOut" }}
        className="text-green-500 mb-6"
      >
        <FaCheckCircle size={80} />
      </motion.div>
      <motion.h1
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.5, duration: 0.8 }}
        className="text-center font-bold text-4xl mb-6"
      >
        Email Verified
      </motion.h1>
      <motion.p
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.8, duration: 0.8 }}
        className="text-center text-lg text-gray-700"
      >
        Your email has been successfully verified. You can now enjoy all the
        features of our platform.
      </motion.p>
      <motion.button
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ delay: 1.2, duration: 0.5 }}
        className="mt-10 bg-green-500 text-white px-6 py-3 rounded-full text-lg font-semibold hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-300 focus:ring-opacity-50 transition-colors duration-300"
        onClick={() => (window.location.href = "/home")}
      >
        Go to Homepage
      </motion.button>
    </motion.div>
  );
};

export default EmailVerified;
