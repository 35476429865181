// src/components/ManageHousesPage.js

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import {
  FiEdit,
  FiTrash2,
  FiSearch,
  FiArrowLeft,
  FiArrowRight,
} from "react-icons/fi";

// Import Toastify components
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Ensure Modal is attached to the app root for accessibility
Modal.setAppElement("#root");

const ManageHousesPage = () => {
  const [houses, setHouses] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedHouseId, setSelectedHouseId] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5; // Number of houses per page
  const navigate = useNavigate();
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  const [userId, setUserId] = useState("");

  useEffect(() => {
    const userId = localStorage.getItem("userId");
    if (userId) {
      setUserId(userId);

      const fetchHouses = async () => {
        try {
          const response = await axios.get(
            `${backendUrl}/houses/userhouses/${userId}`
          );
          setHouses(response.data);
        } catch (error) {
          console.error("Error fetching houses:", error);
          toast.error("Failed to fetch your houses. Please try again.");
        }
      };
      fetchHouses();
    } else {
      toast.error("User not authenticated. Please log in.");
      navigate("/login");
    }
  }, [backendUrl, navigate]);

  const handleDelete = async (houseId) => {
    try {
      await axios.delete(`${backendUrl}/houses/house-details`, {
        data: { userId, propertyId: houseId },
      });

      setHouses(houses.filter((house) => house.id !== houseId));
      setModalVisible(false);
      toast.success("Property deleted successfully.");
    } catch (error) {
      console.error("Error deleting house:", error);
      toast.error("Failed to delete the property. Please try again.");
    }
  };

  const confirmDelete = (houseId) => {
    setSelectedHouseId(houseId);
    setModalVisible(true);
  };

  const handleEdit = (houseId) => {
    navigate(`/edit-house/${houseId}`);
  };

  // Pagination controls
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const filteredHouses = houses.filter(
    (house) =>
      house.house_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      house.location.toLowerCase().includes(searchQuery.toLowerCase()) ||
      house.tag.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const currentHouses = filteredHouses.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredHouses.length / itemsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  return (
    <>
     <head>
        <title>HousemeNG | Manage Houses</title>
      </head>
    <div className="font-halant">
      <ToastContainer />
      <main className="container mx-auto px-6 py-8 mt-24">
        <h2 className="text-3xl font-semibold mb-6 text-gray-800">
          Manage Your Houses
        </h2>

        {/* Search and Add Button */}
        <div className="flex flex-col md:flex-row justify-between items-center mb-8 space-y-4 md:space-y-0">
          {/* Search Input */}
          <div className="relative w-full md:w-1/3">
            <FiSearch className="absolute left-3 top-3 text-gray-400" />
            <input
              type="text"
              placeholder="Search by Title, Location, or Type"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full pl-10 pr-4 py-3 rounded-md border border-gray-300 bg-white text-gray-800 focus:outline-none focus:ring-2 focus:ring-green-500"
            />
          </div>

          {/* Add New Property Button */}
          <button
            onClick={() => navigate("/addProperties")}
            className="w-full md:w-auto bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded-md font-semibold flex items-center justify-center space-x-2"
          >
            <FiEdit className="mr-2" /> Add New Property
          </button>
        </div>

        {/* Houses Table */}
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white rounded-lg overflow-hidden">
            <thead>
              <tr className="bg-gray-100 text-left">
                <th className="px-6 py-3 text-sm font-bold text-gray-700">#</th>
                <th className="px-6 py-3 text-sm font-bold text-gray-700">
                  Title
                </th>
                <th className="px-6 py-3 text-sm font-bold text-gray-700">
                  Location
                </th>
                <th className="px-6 py-3 text-sm font-bold text-gray-700">
                  Price
                </th>
                <th className="px-6 py-3 text-sm font-bold text-gray-700">
                  Type
                </th>
                <th className="px-6 py-3 text-sm font-bold text-gray-700">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="text-gray-600">
              {currentHouses.length > 0 ? (
                currentHouses.map((house, index) => (
                  <tr
                    key={house.id}
                    className="border-t border-gray-200 hover:bg-gray-50"
                  >
                    <td data-label="#" className="px-6 py-4 whitespace-nowrap">
                      {indexOfFirstItem + index + 1}
                    </td>
                    <td
                      data-label="Title"
                      className="px-6 py-4 whitespace-nowrap"
                    >
                      {house.house_name}
                    </td>
                    <td
                      data-label="Location"
                      className="px-6 py-4 whitespace-nowrap"
                    >
                      {house.location}
                    </td>
                    <td
                      data-label="Price"
                      className="px-6 py-4 whitespace-nowrap"
                    >
                      ₦{parseFloat(house.price).toLocaleString()}
                    </td>
                    <td
                      data-label="Type"
                      className="px-6 py-4 whitespace-nowrap"
                    >
                      {house.tag}
                    </td>
                    <td
                      data-label="Actions"
                      className="px-6 py-4 whitespace-nowrap"
                    >
                      <div className="flex space-x-4">
                        <button
                          onClick={() => handleEdit(house.id)}
                          className="flex items-center text-blue-500 hover:text-blue-700"
                          title="Edit Property"
                          aria-label={`Edit ${house.house_name}`}
                        >
                          <FiEdit className="mr-1" /> Edit
                        </button>
                        <button
                          onClick={() => confirmDelete(house.id)}
                          className="flex items-center text-red-500 hover:text-red-700"
                          title="Delete Property"
                          aria-label={`Delete ${house.house_name}`}
                        >
                          <FiTrash2 className="mr-1" /> Delete
                        </button>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6" className="py-4 text-center text-gray-600">
                    No houses found.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {/* Pagination Controls */}
        <div className="flex justify-between items-center mt-6">
          <button
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
            className={`px-4 py-2 rounded-md font-semibold flex items-center ${
              currentPage === 1
                ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                : "bg-green-500 hover:bg-green-600 text-white"
            }`}
          >
            <FiArrowLeft className="mr-2" /> Previous
          </button>
          <span className="text-lg font-medium text-gray-800">
            Page {currentPage} of {totalPages}
          </span>
          <button
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
            className={`px-4 py-2 rounded-md font-semibold flex items-center ${
              currentPage === totalPages
                ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                : "bg-green-500 hover:bg-green-600 text-white"
            }`}
          >
            Next <FiArrowRight className="ml-2" />
          </button>
        </div>

        {/* Delete Confirmation Modal */}
        <Modal
          isOpen={modalVisible}
          onRequestClose={() => setModalVisible(false)}
          contentLabel="Delete Confirmation"
          className="bg-white rounded-lg shadow-lg max-w-md mx-auto p-6 relative"
          overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
        >
          <button
            onClick={() => setModalVisible(false)}
            className="absolute top-3 right-3 text-gray-500 hover:text-gray-700 focus:outline-none"
            aria-label="Close Delete Confirmation Modal"
          >
            &times;
          </button>
          <h2 className="text-2xl font-bold mb-4 text-gray-800">
            Delete Property
          </h2>
          <p className="text-lg text-gray-600 mb-6">
            Are you sure you want to delete this property?
          </p>
          <div className="flex justify-end space-x-4">
            <button
              onClick={() => handleDelete(selectedHouseId)}
              className="bg-red-500 hover:bg-red-600 text-white py-2 px-4 rounded-md font-semibold flex items-center space-x-2"
            >
              <FiTrash2 /> Yes, Delete
            </button>
            <button
              onClick={() => setModalVisible(false)}
              className="bg-gray-300 hover:bg-gray-400 text-gray-800 py-2 px-4 rounded-md font-semibold"
            >
              Cancel
            </button>
          </div>
        </Modal>
      </main>
    </div>
    </>
  );
};

export default ManageHousesPage;
