import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "./AuthContext"; // Import AuthContext

const ProtectedRoute = ({ children }) => {
  const { isLoggedIn, loading } = useAuth(); // Include loading state

  if (loading) {
    // While checking login status, show a loader or nothing
    return <p>Loading...</p>; // You can add a proper loader here
  }

  // If not logged in, redirect to the homepage
  if (!isLoggedIn) {
    return <Navigate to="/home" />;
  }

  // If logged in, render the children (protected page)
  return children;
};

export default ProtectedRoute;
