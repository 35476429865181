// src/components/SignUp.js

import React, { useState, useEffect, useRef, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { FaUserPlus, FaRegSadCry, FaEye, FaEyeSlash } from "react-icons/fa";
import bgImage from "../assets/images/signUpBgImage.jpg";
import { FcGoogle } from "react-icons/fc";

const SignUp = () => {
  const [title, setTitle] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [userName, setUserName] = useState("");
  const [userNameError, setUserNameError] = useState("");
  const [isUserNameUnique, setIsUserNameUnique] = useState(null);
  const [state, setState] = useState("");
  const [country, setCountry] = useState("Nigeria");
  const [email, setEmail] = useState("");
  const [userType, setUserType] = useState("3");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  const navigate = useNavigate();

  // Password visibility states
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  // useRef to store the debounce timer
  const debounceTimer = useRef(null);

  // Memoized function to check username uniqueness and validity
  const checkUserName = useCallback(
    async (usernameToCheck) => {
      if (!usernameToCheck) {
        setUserNameError("");
        setIsUserNameUnique(null);
        return;
      }

      // Validate username: letters and numbers, 7-17 characters, no spaces
      const isValid = /^[a-zA-Z0-9]{7,17}$/.test(usernameToCheck);
      if (!isValid) {
        setUserNameError(
          "Username must be 7-17 characters long and can only contain letters and numbers without spaces."
        );
        setIsUserNameUnique(false);
        return;
      }

      try {
        const response = await axios.get(`${backendUrl}/auth/check-username`, {
          params: { username: usernameToCheck },
        });

        if (response.data.isUnique) {
          setUserNameError("");
          setIsUserNameUnique(true);
        } else {
          setUserNameError("Username is already taken.");
          setIsUserNameUnique(false);
        }
      } catch (err) {
        console.error("Error checking username:", err);
        setUserNameError("Unable to verify username. Please try again.");
        setIsUserNameUnique(false);
      }
    },
    [backendUrl]
  );

  // Effect to validate password length in real-time
  useEffect(() => {
    if (password && password.length < 8) {
      setPasswordError("Password must be at least 8 characters long.");
    } else {
      setPasswordError("");
    }
  }, [password]);

  // Effect to handle debounced username check
  useEffect(() => {
    // Clear the previous timer
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }

    // Set a new timer
    debounceTimer.current = setTimeout(() => {
      checkUserName(userName);
    }, 500); // 500ms delay

    // Cleanup function to clear the timer when component unmounts or userName changes
    return () => {
      if (debounceTimer.current) {
        clearTimeout(debounceTimer.current);
      }
    };
  }, [userName, checkUserName]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Trim the email before submission
    const trimmedEmail = email.trim().toLowerCase();

    // Basic validation
    if (password !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    // Validate password length
    if (password.length < 8) {
      setError("Password must be at least 8 characters long.");
      return;
    }

    // Validate username again before submission
    const isValidUserName = /^[a-zA-Z0-9]{7,17}$/.test(userName);
    if (!isValidUserName) {
      setError(
        "Username must be 7-17 characters long and can only contain letters and numbers without spaces."
      );
      return;
    }

    if (!isUserNameUnique) {
      setError("Please choose a unique username.");
      return;
    }

    // Clear previous error
    setError("");

    // Prepare data for submission
    const userData = {
      title: title || null,
      firstName,
      lastName,
      userName,
      state,
      email: trimmedEmail,
      userType,
      country,
      password,
    };

    try {
      const response = await axios.post(`${backendUrl}/auth/signup`, userData);

      if (response.status === 200) {
        // Redirect to login page on successful signup
        navigate("/login");
      } else {
        throw new Error("Signup failed.");
      }
    } catch (error) {
      console.error("Signup Error:", error);
      setError(
        error.response?.data?.error || "An error occurred. Please try again."
      );
    }
  };

  return (
    <>
      <head>
        <title>HousemeNG | Sign Up</title>
      </head>
      <div className="flex flex-col md:flex-row min-h-screen bg-gray-100 dark:bg-gray-900 relative">
        {/* Left Side - Image */}
        <div className="hidden md:block md:w-1/2 h-full fixed left-0 top-0">
          <img
            src={bgImage}
            alt="Building"
            className="object-cover w-full h-full"
          />
        </div>

        {/* Right Side - Form */}
        <div className="flex flex-col justify-center w-full md:w-1/2 px-6 md:px-16 lg:px-2 right mt-28 md:ml-[50%] bg-gray-100 dark:bg-gray-900">
          <div className="mx-auto w-full max-w-md">
            <div className="flex justify-center mb-6">
              <FaUserPlus className="text-green-700 dark:text-green-500 w-16 h-16" />
            </div>
            <h2 className="text-3xl font-bold text-center text-gray-800 dark:text-white">
              Create an Account
            </h2>
            <p className="text-sm text-center text-gray-600 dark:text-gray-300 mt-2">
              Enter your details to sign up
            </p>
            {error && (
              <div className="bg-red-100 text-red-700 px-4 py-3 rounded mt-6 flex items-center">
                <FaRegSadCry className="mr-2" />
                <span>{error}</span>
              </div>
            )}
            <form onSubmit={handleSubmit} className="mt-8">
              {/* Title */}
              <div>
                <label
                  htmlFor="title"
                  className="block text-sm font-medium text-gray-700 dark:text-gray-300"
                >
                  Title
                </label>
                <select
                  id="title"
                  className="mt-1 w-full px-3 py-2 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-700 rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                >
                  <option value="">-- Select Title --</option>
                  <option value="Mr.">Mr.</option>
                  <option value="Ms.">Ms.</option>
                  <option value="Mrs.">Mrs.</option>
                </select>
              </div>

              {/* First Name */}
              <div className="mt-4">
                <label
                  htmlFor="firstName"
                  className="block text-sm font-medium text-gray-700 dark:text-gray-300"
                >
                  First Name<span className="text-red-500 ml-1">*</span>
                </label>
                <input
                  type="text"
                  id="firstName"
                  placeholder="Enter your first name"
                  className="mt-1 w-full px-3 py-2 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-700 rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  required
                />
              </div>

              {/* Last Name */}
              <div className="mt-4">
                <label
                  htmlFor="lastName"
                  className="block text-sm font-medium text-gray-700 dark:text-gray-300"
                >
                  Last Name<span className="text-red-500 ml-1">*</span>
                </label>
                <input
                  type="text"
                  id="lastName"
                  placeholder="Enter your last name"
                  className="mt-1 w-full px-3 py-2 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-700 rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  required
                />
              </div>

              {/* Username */}
              <div className="mt-4">
                <label
                  htmlFor="userName"
                  className="block text-sm font-medium text-gray-700 dark:text-gray-300"
                >
                  Username<span className="text-red-500 ml-1">*</span>
                </label>
                <input
                  type="text"
                  id="userName"
                  placeholder="Choose a username"
                  className={`mt-1 w-full px-3 py-2 bg-white dark:bg-gray-800 border ${
                    userNameError
                      ? "border-red-500"
                      : "border-gray-300 dark:border-gray-700"
                  } rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500`}
                  value={userName}
                  onChange={(e) => setUserName(e.target.value)}
                  required
                />
                {userNameError && (
                  <p className="text-red-500 text-sm mt-1">{userNameError}</p>
                )}
                {isUserNameUnique && (
                  <p className="text-green-500 text-sm mt-1">
                    Username is available.
                  </p>
                )}
              </div>

              {/* State */}
              <div className="mt-4">
                <label
                  htmlFor="state"
                  className="block text-sm font-medium text-gray-700 dark:text-gray-300"
                >
                  State<span className="text-red-500 ml-1">*</span>
                </label>
                <select
                  id="state"
                  className="mt-1 w-full px-3 py-2 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-700 rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500"
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                  required
                >
                  <option value="">-- Select State --</option>
                  {/* Add your state options here */}
                  <option value="Lagos">Lagos</option>
                  <option value="Abuja">Abuja</option>
                  <option value="Abia">Abia</option>
                  <option value="Adamawa">Adamawa</option>
                  <option value="Akwa Ibom">Akwa Ibom</option>
                  <option value="Anambra">Anambra</option>
                  <option value="Bauchi">Bauchi</option>
                  <option value="Bayelsa">Bayelsa</option>
                  <option value="Benue">Benue</option>
                  <option value="Borno">Borno</option>
                  <option value="Cross River">Cross River</option>
                  <option value="Delta">Delta</option>
                  <option value="Ebonyi">Ebonyi</option>
                  <option value="Edo">Edo</option>
                  <option value="Ekiti">Ekiti</option>
                  <option value="Enugu">Enugu</option>
                  <option value="Gombe">Gombe</option>
                  <option value="Imo">Imo</option>
                  <option value="Jigawa">Jigawa</option>
                  <option value="Kaduna">Kaduna</option>
                  <option value="Kano">Kano</option>
                  <option value="Katsina">Katsina</option>
                  <option value="Kebbi">Kebbi</option>
                  <option value="Kogi">Kogi</option>
                  <option value="Kwara">Kwara</option>
                  <option value="Nasarawa">Nasarawa</option>
                  <option value="Niger">Niger</option>
                  <option value="Ogun">Ogun</option>
                  <option value="Ondo">Ondo</option>
                  <option value="Osun">Osun</option>
                  <option value="Oyo">Oyo</option>
                  <option value="Plateau">Plateau</option>
                  <option value="Rivers">Rivers</option>
                  <option value="Sokoto">Sokoto</option>
                  <option value="Taraba">Taraba</option>
                  <option value="Yobe">Yobe</option>
                  <option value="Zamfara">Zamfara</option>
                  {/* ... other states ... */}
                </select>
              </div>

              {/* Country */}
              <div className="mt-4">
                <label
                  htmlFor="country"
                  className="block text-sm font-medium text-gray-700 dark:text-gray-300"
                >
                  Country of Residence
                  <span className="text-red-500 ml-1">*</span>
                </label>
                <select
                  id="country"
                  className="mt-1 w-full px-3 py-2 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-700 rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500"
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                  required
                >
                  <option value="Nigeria">Nigeria</option>
                  <option value="Other">Other</option>
                </select>
              </div>

              {/* Email */}
              <div className="mt-4">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700 dark:text-gray-300"
                >
                  Email Address<span className="text-red-500 ml-1">*</span>
                </label>
                <input
                  type="email"
                  id="email"
                  placeholder="Enter your email"
                  className="mt-1 w-full px-3 py-2 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-700 rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500"
                  value={email}
                  onChange={(e) =>
                    setEmail(e.target.value.trim().toLowerCase())
                  }
                  required
                />
              </div>

              {/* User Type */}
              <div className="mt-4">
                <label
                  htmlFor="userType"
                  className="block text-sm font-medium text-gray-700 dark:text-gray-300"
                >
                  User Type
                </label>
                <select
                  id="userType"
                  className="mt-1 w-full px-3 py-2 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-700 rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500"
                  value={userType}
                  onChange={(e) => setUserType(e.target.value)}
                >
                  <option value="3">Buyer and Seller</option>
                  <option value="2">Buyer</option>
                  <option value="1">Seller</option>
                </select>
              </div>

              {/* Password */}
              <div className="mt-4 relative">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700 dark:text-gray-300"
                >
                  Password<span className="text-red-500 ml-1">*</span>
                </label>
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  placeholder="Password"
                  className={`mt-1 w-full px-3 py-2 pr-10 bg-white dark:bg-gray-800 border ${
                    passwordError
                      ? "border-red-500"
                      : "border-gray-300 dark:border-gray-700"
                  } rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500`}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  autoComplete="new-password"
                />
                <button
                  type="button"
                  className="absolute inset-y-0 right-0 px-3 flex items-center text-gray-600 dark:text-gray-300"
                  onClick={() => setShowPassword(!showPassword)}
                  tabIndex={-1}
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </button>
                {passwordError && (
                  <p className="text-red-500 text-sm mt-1">{passwordError}</p>
                )}
                <p className="text-xs text-gray-500 mt-1">
                  Must be at least 8 characters.
                </p>
              </div>

              {/* Confirm Password */}
              <div className="mt-4 relative">
                <label
                  htmlFor="confirmPassword"
                  className="block text-sm font-medium text-gray-700 dark:text-gray-300"
                >
                  Confirm Password<span className="text-red-500 ml-1">*</span>
                </label>
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  id="confirmPassword"
                  placeholder="Confirm Password"
                  className="mt-1 w-full px-3 py-2 pr-10 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-700 rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                  autoComplete="new-password"
                />
                <button
                  type="button"
                  className="absolute inset-y-0 right-0 px-3 flex items-center text-gray-600 dark:text-gray-300 h-[135%]"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  tabIndex={-1}
                >
                  {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                </button>
              </div>

              {/* Display general error */}
              {error && <p className="text-red-500 mt-4">{error}</p>}
              <button
                type="submit"
                className={`mt-6 w-full py-3 bg-green-700 hover:bg-green-800 text-white font-semibold rounded-md shadow focus:outline-none focus:ring-2 focus:ring-green-500 ${
                  !isUserNameUnique || isUserNameUnique === null
                    ? "opacity-50 cursor-not-allowed"
                    : ""
                }`}
                disabled={!isUserNameUnique || isUserNameUnique === null}
              >
                Sign Up
              </button>
            </form>
            <div className="flex items-center my-6">
              <hr className="flex-grow border-t border-gray-300 dark:border-gray-700" />
              <span className="mx-4 text-gray-500 dark:text-gray-400">OR</span>
              <hr className="flex-grow border-t border-gray-300 dark:border-gray-700" />
            </div>
            <button className="w-full py-3 flex items-center justify-center bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-700 rounded-md shadow-sm text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
              <FcGoogle className="w-6 h-6 mr-2" />
              Continue with Google
            </button>
            <p className="mt-6 text-center text-sm text-gray-600 dark:text-gray-400">
              Already have an account?{" "}
              <Link
                to="/login"
                className="text-green-700 dark:text-green-400 hover:underline"
              >
                Log in
              </Link>
            </p>
          </div>
          <br />
          <br />
        </div>
      </div>
    </>
  );
};

export default SignUp;
