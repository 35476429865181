import { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Modal from "react-modal";
import "./adminkyc.css";

const AdminVerificationPage = () => {
  const [documents, setDocuments] = useState([]);
  const [filterStatus, setFilterStatus] = useState("");
  const [searchQuery, setSearchQuery] = useState(""); // State for search input
  const [selectedReason, setSelectedReason] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [currentUserId, setCurrentUserId] = useState(null);
  const [imageModalVisible, setImageModalVisible] = useState(false);
  const [modalImageSrc, setModalImageSrc] = useState("");
  const [dropdownVisible, setDropdownVisible] = useState(null); // State to track which dropdown is open
  const [currentPage, setCurrentPage] = useState(1); // State for pagination
  const [itemsPerPage] = useState(5); // Number of documents per page
  const [verificationStatus, setVerificationStatus] = useState(null);
  const navigate = useNavigate(); // To programmatically navigate

  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const backendUrl2 = process.env.REACT_APP_BACKEND_URL2;

  const [userId, setUserId] = useState("");

  useEffect(() => {
    const userId = localStorage.getItem("userId");
    if (userId) {
      setUserId(userId);
    }
  }, []);

  // Fetch verification status when component mounts
  useEffect(() => {
    const fetchVerificationStatus = async () => {
      try {
        const response = await axios.get(
          `${backendUrl}/users/admincheck/${userId}`
        );
        const { verification } = response.data;
        setVerificationStatus(verification); // Store the verification status

        // If verification status is not 1, redirect to the previous page
        if (verification !== 1) {
          window.history.back(); // Takes the user to the previous page they came from
        }
      } catch (error) {
        console.error("Error fetching verification status:", error);
      }
    };

    if (userId) {
      fetchVerificationStatus();
    }
  }, [backendUrl, userId, navigate]);

  const fetchDocuments = useCallback(async () => {
    try {
      const response = await axios.get(`${backendUrl}/kyc/admin/documents`, {
        params: { status: filterStatus },
      });
      setDocuments(response.data);
    } catch (error) {
      console.error("Error fetching documents:", error);
    }
  }, [backendUrl, filterStatus]);

  useEffect(() => {
    fetchDocuments();
  }, [filterStatus, fetchDocuments]);

  const handleStatusChange = async (userId, newStatus) => {
    if (newStatus === 2) {
      setCurrentUserId(userId);
      setModalVisible(true);
    } else {
      await updateStatus(userId, newStatus, null);
    }
  };

  const updateStatus = async (userId, newStatus, reason) => {
    try {
      await axios.post(`${backendUrl}/kyc/admin/update-status`, {
        userId,
        verified: newStatus,
        statusReason: reason,
      });
      fetchDocuments(); // Refresh the table
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  const handleModalSubmit = async () => {
    if (selectedReason.trim()) {
      await updateStatus(currentUserId, 2, selectedReason); // Denied status
      setModalVisible(false);
      setSelectedReason("");
    }
  };

  const showImageModal = (imageSrc) => {
    setModalImageSrc(imageSrc);
    setImageModalVisible(true);
  };

  const toggleDropdown = (docId) => {
    setDropdownVisible(dropdownVisible === docId ? null : docId);
  };

  // Pagination controls
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentDocuments = documents
    .filter(
      (doc) =>
        doc.name_on_document
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        doc.id_number.includes(searchQuery) ||
        doc.document_type.toLowerCase().includes(searchQuery.toLowerCase()) // Filter by document type
    )
    .slice(indexOfFirstItem, indexOfLastItem); // Pagination logic

  const totalPages = Math.ceil(documents.length / itemsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  return ( 
    <>
     <head>
        <title>HousemeNG | Admin Verification</title>
      </head>
    <div className="mt-32 px-6 font-halant">
      <h1 className="text-center font-bold text-3xl mb-8">
        Admin Document Verification
      </h1>

      <div className="flex justify-between items-center mb-8">
        {/* Filter Dropdown */}
        <div className="flex flex-col">
          <label className="font-semibold text-lg mb-2">
            Filter by Status:
          </label>
          <select
            onChange={(e) => setFilterStatus(e.target.value)}
            value={filterStatus}
            className="w-36 rounded-md font-medium border-gray-300 focus:outline-none focus:ring-2 focus:ring-green-600 focus:border-transparent"
          >
            <option className="hover:bg-green-700" value="">
              All
            </option>
            <option value="1">Pending</option>
            <option value="2">Denied</option>
            <option value="3">Approved</option>
          </select>
        </div>

        {/* Search Bar */}
        <div className="flex flex-col">
          <label className="font-semibold text-lg mb-2">Search:</label>
          <input
            type="text"
            placeholder="Search by name, #ID, or ID type"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-64 rounded-md border-gray-300 px-3 font-halant font-semibold py-2 focus:outline-none focus:ring-2 focus:ring-green-600 focus:border-transparent"
          />
        </div>
      </div>

      {/* Table with Pagination and Search */}
      <div>
        <table className="min-w-full border-collapse">
          <thead>
            <tr className="bg-gray-100 border-b">
              <th className="px-4 py-3">#</th>
              <th className="px-4 py-3">ID Number</th>
              <th className="px-4 py-3">Name</th>
              <th className="px-4 py-3">ID Type</th>
              <th className="px-4 py-3">Front Image</th>
              <th className="px-4 py-3">Back Image</th>
              <th className="px-4 py-3">Status</th>
              <th className="px-4 py-3">Reason</th>
            </tr>
          </thead>
          <tbody className="font-semibold">
            {currentDocuments.map((doc, index) => (
              <tr key={doc.id} className="border-b">
                <td data-label="#" className="px-4 py-3">
                  {indexOfFirstItem + index + 1}
                </td>
                <td data-label="ID Number" className="px-4 py-3">
                  {doc.id_number}
                </td>
                <td data-label="Name" className="px-4 py-3">
                  {doc.name_on_document}
                </td>
                <td data-label="ID Type" className="px-4 py-3">
                  {doc.document_type}
                </td>
                <td data-label="Front Image" className="px-4 py-3">
                  <img
                    className="object-cover"
                    src={`${backendUrl2}/${doc.front_document_path}`}
                    alt="Front of Document"
                    style={{
                      width: "100px",
                      height: "100px",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      showImageModal(
                        `${backendUrl2}/${doc.front_document_path}`
                      )
                    }
                  />
                </td>
                <td data-label="Back Image" className="px-4 py-3">
                  <img
                    className="object-cover"
                    src={`${backendUrl2}/${doc.back_document_path}`}
                    alt="Back of Document"
                    style={{
                      width: "100px",
                      height: "100px",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      showImageModal(`${backendUrl2}/${doc.back_document_path}`)
                    }
                  />
                </td>
                <td data-label="Status" className="px-4 py-3">
                  <div>
                    <button
                      className="dropbtn bg-gray-200 hover:bg-gray-300 text-gray-700 px-4 py-1 rounded-full"
                      onClick={() => toggleDropdown(doc.id)}
                    >
                      {doc.verification === 1
                        ? "Pending"
                        : doc.verification === 2
                        ? "Denied"
                        : "Approved"}
                    </button>
                    {dropdownVisible === doc.id && (
                      <div className="dropdown-content absolute bg-white shadow-md rounded mt-2 z-10">
                        <p
                          className="block px-4 py-2 text-sm hover:bg-gray-100 cursor-pointer"
                          onClick={() => handleStatusChange(doc.unique_id, 1)}
                        >
                          Pending
                        </p>
                        <p
                          className="block px-4 py-2 text-sm hover:bg-gray-100 cursor-pointer"
                          onClick={() => handleStatusChange(doc.unique_id, 2)}
                        >
                          Denied
                        </p>
                        <p
                          className="block px-4 py-2 text-sm hover:bg-gray-100 cursor-pointer"
                          onClick={() => handleStatusChange(doc.unique_id, 3)}
                        >
                          Approved
                        </p>
                      </div>
                    )}
                  </div>
                </td>
                <td data-label="Reason" className="px-4 py-3">
                  {doc.reason || "N/A"}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination Controls */}
      <div className="flex justify-between mt-4 font-halant font-semibold mb-6">
        <button
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
          className="px-4 py-2 bg-gray-200 rounded-md disabled:opacity-50"
        >
          Previous
        </button>
        <span>
          Page {currentPage} of {totalPages}
        </span>
        <button
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
          className="px-4 py-2 bg-gray-200 rounded-md disabled:opacity-50"
        >
          Next
        </button>
      </div>

      {/* Modal for Denial Reason */}
      <Modal
        isOpen={modalVisible}
        onRequestClose={() => setModalVisible(false)}
        contentLabel="Denial Reason"
        className="fixed inset-0 flex items-center justify-center z-50"
        overlayClassName="bg-black bg-opacity-50 fixed inset-0 z-40"
      >
        <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full text-center">
          <h3 className="text-lg font-semibold mb-4">
            Provide Reason for Denial
          </h3>
          <textarea
            value={selectedReason}
            onChange={(e) => setSelectedReason(e.target.value)}
            placeholder="Enter reason for denial"
            className="w-full p-3 border border-gray-300 rounded-lg mb-4"
          />
          <div className="flex justify-end space-x-4">
            <button
              className="bg-red-600 text-white px-6 py-2 rounded-lg hover:bg-red-700"
              onClick={handleModalSubmit}
            >
              Confirm
            </button>
            <button
              className="bg-gray-400 text-white px-6 py-2 rounded-lg hover:bg-gray-500"
              onClick={() => setModalVisible(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>

      {/* Modal for Image View */}
      {imageModalVisible && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
          onClick={() => setImageModalVisible(false)}
        >
          <div className="bg-white w-[450px] h-[450px] justify-center p-4 rounded-lg">
            <img
              src={modalImageSrc}
              alt="Enlarged Document"
              className="h-[100%] w-[100%] object-contain"
            />
          </div>
        </div>
      )}
    </div>
    </>
  );
};

export default AdminVerificationPage;
