import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const PersonalInformation = () => {
  const navigate = useNavigate(); // For redirecting the user

  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const [formData, setFormData] = useState({
    title: "",
    name: "",
    email: "",
    phoneNumber: "",
    residentialAddress: "",
    officeAddress: "",
    stateOfResidence: "",
    countryOfResidence: "Nigeria",
  });

  const [userId, setUserId] = useState("");

  useEffect(() => {
    const userId = localStorage.getItem("userId");
    if (userId) {
      setUserId(userId);
    }
  }, []);

  // Fetch user data from backend using userId stored in session storage
  useEffect(() => {
    if (userId) {
      axios
        .get(`${backendUrl}/kyc/users/${userId}`) // this is the endpoint to get user details
        .then((response) => {
          setFormData({
            title: response.data.title || "",
            name: response.data.name || "",
            email: response.data.email || "",
            phoneNumber: response.data.phone || "",
            residentialAddress: response.data.residential_address || "",
            officeAddress: response.data.office_address || "",
            stateOfResidence: response.data.state || "",
            countryOfResidence: response.data.country || "Nigeria",
          });
        })
        .catch((error) => {
          console.error("Error fetching user data:", error);
        });
    }
  }, [backendUrl, userId]);

  // Handle form input change
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (userId) {
      try {
        await axios.put(`${backendUrl}/kyc/users/${userId}`, formData); // Update user details
        navigate("/docUpload");
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
  };

  return (
    <>
      <head>
        <title>HousemeNG | Personal Information</title>
      </head>
      <div className="min-h-screen flex justify-center items-center font-halant">
        <div className="bg-white p-8 rounded-lg w-full">
          <h2 className="text-2xl font-bold mb-5 text-center mt-24">
            Personal Information
          </h2>
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
              {/* Sidebar */}
              <div className="col-span-1">
                <h3 className="text-xl font-semibold mb-4 mt-0 md:mt-24 md:text-left text-center">
                  Verification Progress
                </h3>
                <ul className="relative flex md:block space-x-4 md:space-x-0 md:space-y-4 items-center justify-center font-semibold">
                  <li className="flex items-center relative flex-col md:flex-row">
                    <div className="bg-green-700 h-4 w-4 rounded-full mb-2 md:mb-0 md:mr-2 flex items-center justify-center">
                      <svg
                        className="w-3 h-3 text-white"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm-1.5-6a1 1 0 011.5-.5L11 13.5l3-3a1 1 0 111.5 1.5l-4 4a1 1 0 01-1.5 0l-2-2a1 1 0 01-.5-1.5z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                    <span className="text-center md:text-left">
                      Personal Information
                    </span>
                  </li>
                  <li className="flex items-center relative flex-col md:flex-row">
                    <div className="bg-green-300 h-4 w-4 rounded-full mb-2 md:mb-0 md:mr-2 flex items-center justify-center">
                      <svg
                        className="w-3 h-3 text-white"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm-1.5-6a1 1 0 011.5-.5L11 13.5l3-3a1 1 0 111.5 1.5l-4 4a1 1 0 01-1.5 0l-2-2a1 1 0 01-.5-1.5z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                    <span className="text-center md:text-left">
                      Document Uploads
                    </span>
                  </li>
                </ul>
              </div>

              {/* Form */}
              <div className="col-span-3">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div>
                    <label className="block mb-2">Title</label>
                    <select
                      name="title"
                      value={formData.title}
                      onChange={handleChange}
                      className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-green-700 focus:border-transparent"
                    >
                      <option>Mr.</option>
                      <option>Mrs.</option>
                      <option>Ms.</option>
                    </select>
                  </div>
                  <div>
                    <label className="block mb-2">Name</label>
                    <input
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-green-700 focus:border-transparent"
                    />
                  </div>
                  <div>
                    <label className="block mb-2">Email Address</label>
                    <input
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-green-700 focus:border-transparent"
                    />
                  </div>
                  <div>
                    <label className="block mb-2">Phone Number</label>
                    <input
                      type="text"
                      name="phoneNumber"
                      value={formData.phoneNumber}
                      onChange={handleChange}
                      className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-green-700 focus:border-transparent"
                    />
                  </div>
                  <div>
                    <label className="block mb-2">Residential Address</label>
                    <input
                      type="text"
                      name="residentialAddress"
                      value={formData.residentialAddress}
                      onChange={handleChange}
                      className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-green-700 focus:border-transparent"
                    />
                  </div>
                  <div>
                    <label className="block mb-2">Office Address</label>
                    <input
                      type="text"
                      name="officeAddress"
                      value={formData.officeAddress}
                      onChange={handleChange}
                      className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-green-700 focus:border-transparent"
                    />
                  </div>
                  <div>
                    <label className="block mb-2">State of Residence</label>
                    <select
                      name="stateOfResidence"
                      value={formData.stateOfResidence}
                      onChange={handleChange}
                      className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-green-700 focus:border-transparent"
                    >
                      <option value="" className="hover:bg-green-700">
                        -- Select State --
                      </option>
                      <option value="Lagos">Lagos</option>
                      <option value="Abuja">Abuja</option>
                      <option value="Abia">Abia</option>
                      <option value="Adamawa">Adamawa</option>
                      <option value="Akwa Ibom">Akwa Ibom</option>
                      <option value="Anambra">Anambra</option>
                      <option value="Bauchi">Bauchi</option>
                      <option value="Bayelsa">Bayelsa</option>
                      <option value="Benue">Benue</option>
                      <option value="Borno">Borno</option>
                      <option value="Cross River">Cross River</option>
                      <option value="Delta">Delta</option>
                      <option value="Ebonyi">Ebonyi</option>
                      <option value="Edo">Edo</option>
                      <option value="Ekiti">Ekiti</option>
                      <option value="Enugu">Enugu</option>
                      <option value="Gombe">Gombe</option>
                      <option value="Imo">Imo</option>
                      <option value="Jigawa">Jigawa</option>
                      <option value="Kaduna">Kaduna</option>
                      <option value="Kano">Kano</option>
                      <option value="Katsina">Katsina</option>
                      <option value="Kebbi">Kebbi</option>
                      <option value="Kogi">Kogi</option>
                      <option value="Kwara">Kwara</option>
                      <option value="Nasarawa">Nasarawa</option>
                      <option value="Niger">Niger</option>
                      <option value="Ogun">Ogun</option>
                      <option value="Ondo">Ondo</option>
                      <option value="Osun">Osun</option>
                      <option value="Oyo">Oyo</option>
                      <option value="Plateau">Plateau</option>
                      <option value="Rivers">Rivers</option>
                      <option value="Sokoto">Sokoto</option>
                      <option value="Taraba">Taraba</option>
                      <option value="Yobe">Yobe</option>
                      <option value="Zamfara">Zamfara</option>
                    </select>
                  </div>
                  <div>
                    <label className="block mb-2">Country of Residence</label>
                    <select
                      name="countryOfResidence"
                      value={formData.countryOfResidence}
                      onChange={handleChange}
                      className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-green-700 focus:border-transparent"
                    >
                      <option>Nigeria</option>
                      <option>Other</option>
                    </select>
                  </div>
                </div>
                <div className="flex justify-center md:justify-end mt-6">
                  <button
                    type="submit"
                    className="bg-green-700 text-white mx-11 md:px-6 px-11 md:py-3 py-4 rounded-lg focus:outline-none focus:ring-2 focus:ring-green-700 focus:ring-opacity-50"
                  >
                    Save & Continue
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default PersonalInformation;
