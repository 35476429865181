// src/components/ForgotPassword.js

import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { FaKey } from "react-icons/fa";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/users/forgot-password`,
        { email }
      );
      setSuccessMessage("Reset link sent to your email!");
      setError("");
    } catch (err) {
      setError("Failed to send reset link. Please try again.");
      setSuccessMessage("");
    }
  };

  return (
    <>
    <head>
        <title>HousemeNG | Forgot Password</title>
      </head>
    <div className="flex min-h-screen bg-gray-100 dark:bg-gray-900 relative">
      {/* Right Side - Form */}
      <div className="w-full overflow-y-auto">
        <div className="flex flex-col justify-center px-6 md:px-16 lg:px-2 min-h-screen">
          <div className="mx-auto w-full max-w-md py-8">
            <div className="flex justify-center mb-6">
              <FaKey className="text-green-700 w-16 h-16" />
            </div>
            <h2 className="text-3xl font-bold text-center text-gray-800 dark:text-white">
              Forgot Password
            </h2>
            <p className="text-sm text-center text-gray-600 dark:text-gray-300 mt-2">
              No worries, we'll send you reset instructions.
            </p>

            {error && (
              <div className="bg-red-100 text-red-700 px-4 py-3 rounded mt-6">
                {error}
              </div>
            )}

            {successMessage && (
              <div className="bg-green-100 text-green-700 px-4 py-3 rounded mt-6">
                {successMessage}
              </div>
            )}

            <form onSubmit={handleSubmit} className="mt-8">
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700 dark:text-gray-300"
                >
                  Email Address
                </label>
                <input
                  type="email"
                  id="email"
                  required
                  placeholder="Enter your email"
                  className="mt-1 w-full px-3 py-2 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-700 rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500"
                  value={email}
                  onChange={(e) => setEmail(e.target.value.toLowerCase())}
                />
              </div>

              <button
                type="submit"
                className="mt-6 w-full py-3 bg-green-700 hover:bg-green-800 text-white font-semibold rounded-md shadow focus:outline-none focus:ring-2 focus:ring-green-500"
              >
                Reset Password
              </button>
            </form>

            <div className="flex items-center justify-center mt-6">
              <Link
                to="/login"
                className="text-green-700 dark:text-green-400 hover:underline flex items-center"
              >
                <svg
                  className="w-5 h-5 mr-2"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fillRule="evenodd"
                    d="M12.293 3.293a1 1 0 010 1.414L7.414 9H17a1 1 0 110 2H7.414l4.879 4.293a1 1 0 01-1.293 1.527l-6-5.5a1 1 0 010-1.527l6-5.5a1 1 0 011.293 0z"
                    clipRule="evenodd"
                  />
                </svg>
                Back to Log In
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default ForgotPassword;
