import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import axios from "axios";
import defaultAvatar from "../../assets/images/avatar.png";
import { FaTimes } from "react-icons/fa";

Modal.setAppElement("#root");

const UserProfileModal = ({ isOpen, onRequestClose, userId }) => {
  const [userData, setUserData] = useState(null);

  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const backendUrl2 = process.env.REACT_APP_BACKEND_URL2;

 useEffect(() => {
   if (userId) {
     const fetchUserData = async () => {
       try {
         const response = await axios.get(
           `${backendUrl}/users/users/${userId}`
         );
         console.log("Fetched user data:", response.data); // Debugging
         setUserData(response.data);
       } catch (error) {
         console.error("Error fetching user data:", error);
       }
     };
     fetchUserData();
   }
 }, [userId, backendUrl]);


  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="User Profile"
      className="modal-content w-full md:max-w-lg mx-auto my-10 bg-white rounded-lg shadow-lg overflow-hidden"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
    >
      {userData ? (
        <div className="p-6 relative">
          <button
            onClick={onRequestClose}
            className="text-gray-500 hover:text-red-500 font-semibold text-xl absolute top-4 right-4 focus:outline-none"
            aria-label="Close Modal"
          >
            <FaTimes />
          </button>
          <div className="text-center mb-6">
            <img
              src={
                userData.avatar
                  ? `${backendUrl2}/${userData.avatar}`
                  : defaultAvatar
              }
              alt={`${userData.firstname || ""} ${userData.lastname || ""}`}
              className="mx-auto w-32 h-32 object-cover rounded-md"
            />
            <h2 className="font-halant font-bold text-2xl mt-4">
              {userData.title || ""} {userData.firstname || ""}{" "}
              {userData.lastname || ""}
            </h2>
            <p className="text-gray-600">@{userData.username || ""}</p>
          </div>
        </div>
      ) : (
        <div className="p-6">
          <button
            onClick={onRequestClose}
            className="text-gray-500 hover:text-red-500 font-semibold text-xl absolute top-4 right-4 focus:outline-none"
            aria-label="Close Modal"
          >
            <FaTimes />
          </button>
          <div className="text-center">
            <p>Loading user data...</p>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default UserProfileModal;
