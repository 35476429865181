import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Modal from "react-modal"; // Importing react-modal

const DocumentsUploads = () => {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const [modalVisible, setModalVisible] = useState(false);
  const [documentData, setDocumentData] = useState({
    idType: "",
    nameOnDocument: "NIN",
    documentNumber: "",
    expiryDate: "",
    frontPage: null,
    backPage: null,
  });
  const [userId, setUserId] = useState("");

  const navigate = useNavigate(); // For redirecting the user

  useEffect(() => {
    const userId = localStorage.getItem("userId");
    if (userId) {
      setUserId(userId);
    }
  }, []);

  useEffect(() => {
    const fetchDocumentData = async () => {
      try {
        const response = await axios.get(
          `${backendUrl}/kyc/verifications/${userId}`
        );
        const data = response.data;

        if (Array.isArray(data) && data.length === 0) {
          console.log("No document data found.");
          return;
        }

        setDocumentData({
          idType: data.document_type || "",
          nameOnDocument: data.name_on_document || "",
          documentNumber: data.id_number || "",
          expiryDate: data.expiry_date || "",
          frontPage: data.front_document_path || null,
          backPage: data.back_document_path || null,
        });
      } catch (error) {
        console.error("Error fetching document data:", error);
      }
    };

    if (userId) {
      fetchDocumentData();
    }
  }, [userId, backendUrl]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setDocumentData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setDocumentData((prevData) => ({
      ...prevData,
      [name]: files[0], // Assuming we store only one file per field
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      Object.keys(documentData).forEach((key) => {
        formData.append(key, documentData[key]);
      });

      await axios.post(
        `${backendUrl}/kyc/verify-documents/${userId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setModalVisible(true); // Show success modal
    } catch (error) {
      console.error("Error uploading document data:", error);
    }
  };

  const handleModalClose = () => {
    setModalVisible(false);
    navigate("/home"); // Navigate to the home page
  };

  return (
    <>
      <head>
        <title>HousemeNG | Documents Upload</title>
      </head>
      <div className="min-h-screen flex justify-center items-center font-halant">
        <div className="p-8 rounded-lg w-full">
          <h2 className="text-2xl font-bold mb-6 text-center mt-24 md:mt-0">
            Documents Uploads
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
            <div className="col-span-1">
              <h3 className="text-xl font-semibold mb-4 mt-0 md:mt-24 md:text-left text-center">
                Verification Progress
              </h3>
              <ul className="relative flex md:block space-x-4 md:space-x-0 md:space-y-4 items-center justify-center font-semibold">
                <li className="flex items-center relative flex-col md:flex-row">
                  <div className="bg-green-700 h-4 w-4 rounded-full mb-2 md:mb-0 md:mr-2 flex items-center justify-center">
                    <svg
                      className="w-3 h-3 text-white"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm-1.5-6a1 1 0 011.5-.5L11 13.5l3-3a1 1 0 111.5 1.5l-4 4a1 1 0 01-1.5 0l-2-2a1 1 0 01-.5-1.5z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                  <span className="text-center md:text-left">
                    Personal Information
                  </span>
                </li>
                <li className="flex items-center relative flex-col md:flex-row">
                  <div className="bg-green-700 h-4 w-4 rounded-full mb-2 md:mb-0 md:mr-2 flex items-center justify-center">
                    <svg
                      className="w-3 h-3 text-white"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm-1.5-6a1 1 0 011.5-.5L11 13.5l3-3a1 1 0 111.5 1.5l-4 4a1 1 0 01-1.5 0l-2-2a1 1 0 01-.5-1.5z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                  <span className="text-center md:text-left">
                    Document Uploads
                  </span>
                </li>
              </ul>
            </div>

            <div className="col-span-3">
              <form onSubmit={handleSubmit}>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div>
                    <label className="block mb-2">Type of ID Document</label>
                    <select
                      name="idType"
                      value={documentData.idType}
                      onChange={handleInputChange}
                      className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-green-700 focus:border-transparent"
                    >
                      <option value="NIN">
                        NIN, International Passport, ID Card
                      </option>
                      <option value="DriversLicense">Driver's License</option>
                      <option value="VotersCard">Voter's Card</option>
                    </select>
                  </div>
                  <div>
                    <label className="block mb-2">Name on ID Document</label>
                    <input
                      type="text"
                      name="nameOnDocument"
                      value={documentData.nameOnDocument}
                      onChange={handleInputChange}
                      className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-green-700 focus:border-transparent"
                    />
                  </div>
                  <div>
                    <label className="block mb-2">ID Document Number</label>
                    <input
                      type="text"
                      name="documentNumber"
                      value={documentData.documentNumber}
                      onChange={handleInputChange}
                      className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-green-700 focus:border-transparent"
                    />
                  </div>
                  <div>
                    <label className="block mb-2">Date of Expiry</label>
                    <input
                      type="date"
                      name="expiryDate"
                      value={documentData.expiryDate}
                      onChange={handleInputChange}
                      className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-green-700 focus:border-transparent"
                    />
                  </div>
                  <div>
                    <label className="block mb-2">Front Page of Document</label>
                    <input
                      type="file"
                      name="frontPage"
                      onChange={handleFileChange}
                      className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-green-700"
                    />
                  </div>
                  <div>
                    <label className="block mb-2">Back Page of Document</label>
                    <input
                      type="file"
                      name="backPage"
                      onChange={handleFileChange}
                      className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-green-700"
                    />
                  </div>
                </div>
                <div className="flex md:justify-end justify-center mt-6">
                  <button
                    type="submit"
                    className="bg-green-700 text-white px-16 md:px-11 py-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-green-700"
                  >
                    Next
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        <Modal
          isOpen={modalVisible}
          onRequestClose={handleModalClose}
          contentLabel="Verification Uploaded"
          className="Modal"
          overlayClassName="modal-overlay"
        >
          <div className="bg-white p-8 rounded-lg shadow-lg text-center">
            <h2 className="text-green-600 mt-4 text-2xl font-bold">
              Documents Successfully Submitted for Verification!
            </h2>
            <button
              className="bg-green-700 text-white px-6 py-2 mt-6 rounded-lg focus:outline-none"
              onClick={handleModalClose}
            >
              Close
            </button>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default DocumentsUploads;
