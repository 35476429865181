import React, { useState, useEffect, useRef, useCallback } from "react";
import { useParams, useNavigate, Link, useLocation } from "react-router-dom";
import axios from "axios";
import io from "socket.io-client";
import defaultAvatar from "../assets/images/avatar.png";
import SendIcon from "@mui/icons-material/Send";
import { FaPaperclip, FaTimes } from "react-icons/fa";
import Modal from "react-modal";
import { KeyboardBackspaceRounded } from "@mui/icons-material";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FundWalletModal from "./reusableComponents/FundWalletModal";
import UserProfileModal from "./reusableComponents/UserProfileModal";

// Set the app element for the modal
Modal.setAppElement("#root");

const Chat = () => {
  const { user1, user2 } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [isPurchaseModalOpen, setIsPurchaseModalOpen] = useState(false);
  const [purchaseMessage, setPurchaseMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFundWalletModalOpen, setIsFundWalletModalOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFilePreview, setSelectedFilePreview] = useState(null);
  const [otherUserDetails, setOtherUserDetails] = useState(null);
  const [userId, setUserId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFundModalOpen, setIsFundModalOpen] = useState(false);
  const [isHouseModalOpen, setIsHouseModalOpen] = useState(false); // Modal state
  const [houses, setHouses] = useState([]); // State to hold fetched house details
  const [caption, setCaption] = useState("");
  const [imageModalOpen, setImageModalOpen] = useState(false);
  const [imageToView, setImageToView] = useState(null);
  const [isAttachmentModalOpen, setIsAttachmentModalOpen] = useState(false);
  const [chats, setChats] = useState([]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const profileMenuRef = useRef(null);
  const [selectedChat, setSelectedChat] = useState(false); // false = show chat list, true = show chat page
  const [screenWidth, setScreenWidth] = useState(window.innerWidth); // Track screen width
  const [walletBalance, setWalletBalance] = useState(0.0); // State for wallet balance
  const chatContainerRef = useRef(null);
  const lastMessageRef = useRef(null); // Reference for the last message
  const [searchTerm, setSearchTerm] = useState(""); // New state for search term
  const [existingProfileImage, setExistingProfileImage] = useState(null); // Image from the backend

  // Search filter logic
  const filteredChats = chats
    .filter((chat) =>
      chat.participantname.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .sort((a, b) => {
      // Prioritize exact matches over partial matches
      const aStartsWith = a.participantname
        .toLowerCase()
        .startsWith(searchTerm.toLowerCase());
      const bStartsWith = b.participantname
        .toLowerCase()
        .startsWith(searchTerm.toLowerCase());

      if (aStartsWith && !bStartsWith) {
        return -1; // a comes first
      }
      if (!aStartsWith && bStartsWith) {
        return 1; // b comes first
      }
      return 0; // maintain original order if both or neither start with the term
    });

  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const backendUrl2 = process.env.REACT_APP_BACKEND_URL2;
  const chatInputRef = useRef(null);

  // Fetch userId from localStorage on mount
  useEffect(() => {
    const storedUserId = localStorage.getItem("userId");
    if (storedUserId) {
      setUserId(storedUserId);
    }
  }, []);

  // Function to fetch wallet balance
  const fetchWalletBalance = useCallback(async () => {
    try {
      if (!userId) return;
      const response = await axios.get(`${backendUrl}/chats/wallet/${userId}`);
      if (response.status === 200) {
        setWalletBalance(response.data.balance);
      } else {
        console.error("Unexpected response status:", response.status);
        toast.error("Failed to fetch wallet balance.");
      }
    } catch (error) {
      console.error("Error fetching wallet balance:", error);
      toast.error("Error fetching wallet balance. Please try again.");
    }
  }, [backendUrl, userId]);

  // Fetch wallet balance on component mount
  useEffect(() => {
    if (userId) {
      fetchWalletBalance();
    }
  }, [userId, fetchWalletBalance]);

  useEffect(() => {
    const userAvatar = localStorage.getItem("avatar");
    if (userAvatar) {
      setExistingProfileImage(userAvatar);
    }
  }, []);

  // Memoize the function to prevent unnecessary re-creation
  const handleSendSessionMessage = useCallback(
    async (sessionMessage) => {
      if (user1 && user2) {
        // Check if user1 and user2 are present
        try {
          const formData = new FormData();
          formData.append("senderId", userId);
          formData.append("message", sessionMessage);
          formData.append("user1", user1);
          formData.append("user2", user2);

          const response = await axios.post(
            `${backendUrl}/chats/sendchats`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );

          // Add the session message to the chat
          setMessages((prevMessages) => [
            ...prevMessages,
            response.data.message,
          ]);
        } catch (error) {
          console.error("Error sending session message:", error);
        }
      }
    },
    [userId, user1, user2, backendUrl] // Dependencies of handleSendSessionMessage
  );

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const sessionId = params.get("session_id");

    // Send session message only if user1 and user2 are defined
    if (sessionId && user1 && user2 && userId) {
      const sessionMessage = `session-${sessionId}`;
      handleSendSessionMessage(sessionMessage); // Call the function
    }
  }, [location.search, user1, user2, userId, handleSendSessionMessage]);

  useEffect(() => {
    if (selectedChat && chatContainerRef.current) {
      // Smoothly scroll the chat container to the bottom
      chatContainerRef.current.scrollTo({
        top: chatContainerRef.current.scrollHeight,
        behavior: "smooth", // This ensures smooth scrolling
      });
    }
  }, [messages, selectedChat]);

  useEffect(() => {
    console.log(user1);
    console.log(user2);
    if (user1 !== ":user1" && user1 !== ":user2") {
      setSelectedChat(true); // If user1 doesn't match either, setSelectedChat to true
    } else {
      setSelectedChat(false); // Otherwise, set it to false
    }
  }, [user1, user2]); // Add user1 and user2 as dependencies so the effect runs when they change

  useEffect(() => {
    const handleClickOutsideProfile = (event) => {
      if (
        profileMenuRef.current &&
        !profileMenuRef.current.contains(event.target)
      ) {
        setIsMenuOpen(false);
      }
    };

    if (isMenuOpen) {
      document.addEventListener("mousedown", handleClickOutsideProfile);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutsideProfile);
    };
  }, [isMenuOpen]);

  useEffect(() => {
    const fetchChats = async () => {
      try {
        const userId = localStorage.getItem("userId");
        if (userId) {
          setUserId(userId);
        }
        const response = await axios.get(
          `${backendUrl}/chats/chatlist/${userId}`,
          {
            params: { userId: userId },
          }
        );
        setChats(response.data);
      } catch (error) {
        console.error("Error fetching chats:", error);
      }
    };

    fetchChats();
  }, [backendUrl]);

  useEffect(() => {
    const socket = io(backendUrl2);

    socket.on("receiveMessage", async (message) => {
      // Check if the message starts with "session-"
      if (message.message.startsWith("session-")) {
        // Extract the house ID from the message
        const houseId = message.message.split("-")[1];

        // Fetch the house details from the backend
        try {
          const response = await axios.get(`${backendUrl}/houses/${houseId}`);
          const house = response.data;

          // Update the message with the house details (house name and first image)
          message.message = house.house_name;
          message.image_url = house.images[0]; // Use the first image from the images array
        } catch (error) {
          console.error("Error fetching house details:", error);
          // If there's an error, keep the session message as it is
        }
      }

      // Add the processed message (either regular or session-processed) to the messages
      setMessages((prevMessages) => [...prevMessages, message]);

      // Update the last message in the chat list
      setChats((prevChats) => {
        const updatedChats = prevChats.map((chat) => {
          if (
            chat.participantid === message.sender_id ||
            chat.participantid === message.receiver_id
          ) {
            return {
              ...chat,
              latestmessage: message.message, // Update the latest message
              latestmessagetimestamp: message.created_at, // Update the timestamp
            };
          }
          return chat;
        });

        // Move the updated chat to the top of the list
        return updatedChats.sort(
          (a, b) =>
            new Date(b.latestmessagetimestamp) -
            new Date(a.latestmessagetimestamp)
        );
      });
    });

    return () => {
      socket.disconnect();
    };
  }, [backendUrl2]);

  useEffect(() => {
    const userId = localStorage.getItem("userId");
    if (userId) {
      setUserId(userId);
    }
  }, []);

  useEffect(() => {
    const fetchUserDetails = async (userId) => {
      try {
        const response = await axios.get(`${backendUrl}/chats/users/${userId}`);
        return response.data;
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };

    const fetchMessages = async () => {
      try {
        const response = await axios.get(`${backendUrl}/chats/chats`, {
          params: { user1, user2 },
        });
        setMessages(response.data.messages);
      } catch (error) {
        console.error("Error fetching messages:", error);
      }
    };

    if (user1 && user2) {
      fetchMessages();
      if (userId) {
        const otherUserId = userId === user1 ? user2 : user1;
        fetchUserDetails(otherUserId).then((data) => setOtherUserDetails(data));
      }
    }
  }, [user1, user2, userId, backendUrl]);

  const handleSendMessage = async () => {
    try {
      const formData = new FormData();
      formData.append("senderId", userId);
      if (newMessage) {
        formData.append("message", newMessage);
      }
      formData.append("user1", user1);
      formData.append("user2", user2);
      formData.append("caption", caption);
      if (selectedFile) {
        formData.append("file", selectedFile);
      }

      const response = await axios.post(
        `${backendUrl}/chats/sendchats`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // Add the new message to the current chat messages
      // setMessages([...messages, response.data.message]);

      // Check if the chat with user2 is already in the chats list
      const chatExists = chats.some((chat) => chat.participantid === user2);

      // If the chat does not exist, fetch new user details and add the chat
      if (!chatExists) {
        const fetchUserDetails = async (userId) => {
          try {
            const userDetailsResponse = await axios.get(
              `${backendUrl}/chats/users/${user2}`
            );
            return userDetailsResponse.data;
          } catch (error) {
            console.error("Error fetching user details:", error);
            return null;
          }
        };

        const newUserDetails = await fetchUserDetails(user2);

        if (newUserDetails) {
          const newChat = {
            participantid: user2,
            participantname: newUserDetails.name,
            participantphoto: newUserDetails.photo
              ? backendUrl2 + "/" + newUserDetails.photo
              : defaultAvatar,
            latestmessage: response.data.message.message,
            latestmessagetimestamp: response.data.message.created_at,
          };

          setChats((prevChats) => [...prevChats, newChat]); // Add new chat to chats list
        }
      }
      setNewMessage("");
      setSelectedFile(null);
      setSelectedFilePreview(null);
      setCaption("");
      setIsModalOpen(false);
      setIsAttachmentModalOpen(false);
      if (chatInputRef.current) {
        chatInputRef.current.style.height = "auto";
      }
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setSelectedFile(file);
      setSelectedFilePreview(URL.createObjectURL(file));
      setIsModalOpen(true);
    }
  };

  const handleRemoveFile = () => {
    setSelectedFile(null);
    setSelectedFilePreview(null);
    setIsModalOpen(false);
    setIsAttachmentModalOpen(false);
  };

  const handleImageClick = (imageUrl) => {
    setImageToView(imageUrl);
    setImageModalOpen(true);
  };

  const handleCloseImageModal = () => {
    setImageModalOpen(false);
    setImageToView(null);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      if (e.shiftKey) {
        // Allow new line when Shift+Enter is pressed
        e.preventDefault();
        setNewMessage((prev) => prev + "\n");
        if (chatInputRef.current) {
          chatInputRef.current.style.height = "auto";
          chatInputRef.current.style.height = `${Math.min(
            chatInputRef.current.scrollHeight,
            150
          )}px`;
        }
      } else {
        // Send message when only Enter is pressed
        e.preventDefault();
        if (newMessage.trim() !== "") {
          handleSendMessage(); // Call the send message function
        }
      }
    }
  };

  const handleInputChange = (e) => {
    setNewMessage(e.target.value);
    if (chatInputRef.current) {
      chatInputRef.current.style.height = "auto";
      chatInputRef.current.style.height = `${Math.min(
        chatInputRef.current.scrollHeight,
        150
      )}px`;
    }
  };

  const isSendButtonDisabled = !newMessage.trim() && !selectedFile;

  const openAttachmentModal = () => {
    setIsAttachmentModalOpen(true);
  };

  const closeAttachmentModal = () => {
    setIsAttachmentModalOpen(false);
  };

  const formatTime = (timeString) => {
    if (!timeString) return "Invalid date";

    // Parse the ISO time string
    const isoTimeString = timeString.replace(" ", "T");
    const date = new Date(isoTimeString);

    if (isNaN(date.getTime())) return "Invalid date";

    const now = new Date();
    const isToday = now.toDateString() === date.toDateString();
    const isYesterday =
      new Date(now.setDate(now.getDate() - 1)).toDateString() ===
      date.toDateString();

    if (isToday) {
      // Display time for today's messages
      const options = { hour: "numeric", minute: "numeric", hour12: true };
      return date.toLocaleTimeString("en-US", options).toLowerCase();
    } else if (isYesterday) {
      // If the message was sent yesterday, display "Yesterday"
      return "Yesterday";
    } else {
      // Display the date for older messages (MM/DD/YYYY format)
      const options = { month: "numeric", day: "numeric", year: "numeric" };
      return date.toLocaleDateString("en-US", options);
    }
  };

  const formatChatListTime = (timeString) => {
    const date = new Date(timeString);
    const now = new Date();

    const isToday = date.toDateString() === now.toDateString();
    const isYesterday =
      new Date(now.setDate(now.getDate() - 1)).toDateString() ===
      date.toDateString();

    if (isToday) {
      // Show the time (e.g., "4:30 PM")
      return date.toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });
    } else if (isYesterday) {
      // Show "Yesterday"
      return "Yesterday";
    } else {
      // Show the date in "MM/DD/YYYY" format
      return date.toLocaleDateString("en-US", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      });
    }
  };

  useEffect(() => {
    document.body.style.overflow = "hidden"; // Disable body scrolling
    return () => {
      document.body.style.overflow = "auto"; // Restore scrolling when the component is unmounted
    };
  }, []);

  // Format price as Naira currency
  const formatPrice = (price) => {
    return new Intl.NumberFormat("en-NG", {
      style: "currency",
      currency: "NGN",
    }).format(price);
  };

  // Fetch houses from the escrow table where status = 0
  const fetchHousesFromEscrow = async () => {
    try {
      const response = await axios.get(
        `${backendUrl}/chats/escrow/houses/${userId}`
      );
      setHouses(response.data.houses);
    } catch (error) {
      console.error("Error fetching houses from escrow:", error);
    }
  };

  // Handle the "Buy" button click to open modal and fetch house details
  const handleBuyClick = () => {
    setIsHouseModalOpen(true); // Open modal
    fetchHousesFromEscrow(); // Fetch house details
  };

  // Handle modal close
  const closeModal = () => {
    setIsHouseModalOpen(false);
  };

  // Function to handle purchasing a house
  const handleBuyHouse = async (houseId) => {
    const userId = localStorage.getItem("userId");

    try {
      const response = await axios.post(`${backendUrl}/chats/buy-house`, {
        houseId,
        userId,
      });

      if (response.data.success) {
        // Purchase successful
        setPurchaseMessage("House successfully purchased");
        setIsSuccess(true);

        // Send notification message to chat
        await sendPaymentNotificationMessage(houseId);
      } else {
        // Insufficient balance
        setPurchaseMessage("Insufficient balance");
        setIsSuccess(false);
      }

      setIsPurchaseModalOpen(true); // Show the result modal
    } catch (error) {
      setPurchaseMessage("Error occurred during purchase");
      setIsSuccess(false);
      setIsPurchaseModalOpen(true); // Show error modal
    }
  };

  const handleRemoveHouse = async (houseId) => {
    try {
      const response = await axios.post(`${backendUrl}/chats/remove-house`, {
        houseId,
        userId,
      });

      if (response.data.success) {
        // Update the houses state to remove the house from the list
        setHouses((prevHouses) =>
          prevHouses.filter((house) => house.id !== houseId)
        );
        toast.success("House removed from pending purchases.");
      } else {
        toast.error("Failed to remove the house.");
      }
    } catch (error) {
      console.error("Error removing house from pending purchases:", error);
      toast.error("Error occurred while removing the house.");
    }
  };

  const sendPaymentNotificationMessage = async (houseId) => {
    try {
      // Fetch house details to get the name
      const houseResponse = await axios.get(`${backendUrl}/houses/${houseId}`);
      const house = houseResponse.data;

      // Craft the message content
      const messageContent = `PAYMENT_NOTIFICATION:${house.house_name}:${houseId}`;

      const formData = new FormData();
      formData.append("senderId", userId);
      formData.append("message", messageContent);
      formData.append("user1", user1);
      formData.append("user2", user2);

      const response = await axios.post(
        `${backendUrl}/chats/sendchats`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // Update messages
      setMessages((prevMessages) => [...prevMessages, response.data.message]);
    } catch (error) {
      console.error("Error sending payment notification message:", error);
    }
  };

  // Function to open Fund Wallet modal
  const handleFundWalletClick = () => {
    setIsFundWalletModalOpen(true);
  };

  // Function to close Fund Wallet modal
  const closeFundWalletModal = () => {
    setIsFundWalletModalOpen(false);
  };

  const openProfileModal = (userId) => {
    if (userId) {
      setSelectedUserId(userId);
      setIsProfileModalOpen(true);
    } else {
      console.error("Invalid userId, cannot open profile modal.");
    }
  };

  const closeProfileModal = () => {
    setIsProfileModalOpen(false);
    setSelectedUserId(null);
  };

  const formatDateSeparator = (dateString) => {
    const date = new Date(dateString);
    const now = new Date();

    const isToday = date.toDateString() === now.toDateString();
    const isYesterday =
      new Date(now.setDate(now.getDate() - 1)).toDateString() ===
      date.toDateString();

    if (isToday) {
      return "Today";
    } else if (isYesterday) {
      return "Yesterday";
    } else {
      return date.toLocaleDateString("en-US", {
        day: "numeric",
        month: "long",
        year: "numeric",
      });
    }
  };

  return (
    <>
      <head>
        <title>HousemeNG | Chat</title>
      </head>
      <div className="flex w-full overflow-y-hidden h-screen mt-[90px] sticky">
        {/* Chat List */}
        <div
          className={`lg:w-[35%] sm:px-[10%] md:px-[20%] lg:px-2 px-5 w-[100%] h-full overflow-y-auto ${
            selectedChat ? "hidden" : "block"
          } lg:block`}
        >
          <div className="font-manrope font-bold text-xl text-left px-2 text-gray-700 mt-5">
            Chats
          </div>
          <div className="mt-2">
            <input
              type="text"
              placeholder="Search"
              className="mb-4 p-2 w-full border rounded-3xl focus:outline-none focus:ring-2 focus:ring-green-600 focus:border-transparent text-sm font-halant font-semibold"
              value={searchTerm} // Bind the input value to the search term state
              onChange={(e) => setSearchTerm(e.target.value)} // Update search term on input change
            />
          </div>

          {filteredChats.length === 0 ? (
            <div className="text-center text-gray-600 font-halant font-semibold p-4">
              No Agent or Landlord/Owner matches that name.
            </div>
          ) : (
            <div className="">
              <div className="">
                {filteredChats.map((chat) => (
                  <Link
                    to={`/chat/${userId}/${chat.participantid}`}
                    key={chat.chat_id}
                    onClick={() => setSelectedChat(true)}
                    className=""
                  >
                    <div className="flex items-center py-3 cursor-pointer hover:bg-gray-200 px-2">
                      <div className="flex-shrink-0">
                        <img
                          src={
                            chat.participantphoto
                              ? backendUrl2 + "/" + chat.participantphoto
                              : defaultAvatar
                          }
                          alt={`${chat.participantname}`}
                          width={20}
                          height={20}
                          className="rounded-full w-[30px] h-[30px] object-cover cursor-pointer"
                          onClick={(e) => {
                            e.preventDefault(); // Prevent default behavior for the link
                            e.stopPropagation(); // Stop propagation to avoid navigating to the link
                            openProfileModal(chat.participantid);
                          }}
                        />
                      </div>
                      <div className="ml-4 flex-1 overflow-hidden font-halant">
                        {/* Make sure to limit width */}
                        <div className="flex items-center justify-between w-full">
                          <h4 className="text-sm font-semibold truncate">
                            {chat.participantname}
                          </h4>
                          <span className="text-xs text-gray-700 text-right italic ml-2 flex-shrink-0">
                            {formatChatListTime(chat.latestmessagetimestamp)}
                          </span>
                        </div>
                        {/* Ensure the last message is properly contained */}
                        <p className="text-xs text-gray-700 overflow-hidden text-ellipsis whitespace-nowrap truncate w-full">
                          {chat.latestmessage}
                        </p>
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          )}
        </div>

        {/* chat box */}
        <div
          className={`lg:w-[65%] w-[100%] h-[80%] flex flex-col overflow-hidden border-t-2 border-l-2 rounded-md ${
            selectedChat || screenWidth > 1024 ? "flex" : "hidden"
          } lg:flex`}
        >
          {selectedChat && (
            <div className="z-10 flex items-center p-3 border-b sticky">
              <div className="flex flex-row hover:cursor-pointer lg:hidden">
                <KeyboardBackspaceRounded
                  onClick={() => setSelectedChat(false)}
                />
              </div>
              {otherUserDetails && (
                <div className="flex flex-row items-center">
                  <img
                    src={
                      otherUserDetails.avatar
                        ? `${backendUrl2}/${otherUserDetails.avatar}`
                        : defaultAvatar
                    }
                    alt={otherUserDetails.name}
                    className="rounded-full w-[30px] h-[30px] object-cover cursor-pointer"
                    onClick={(e) => {
                      e.preventDefault(); // Prevent default behavior
                      e.stopPropagation(); // Stop propagation to avoid any parent element actions
                      openProfileModal(otherUserDetails.unique_id);
                    }}
                  />

                  <div className="ml-2">
                    <h2 className="text-sm font-semibold">
                      {otherUserDetails.name}
                    </h2>
                  </div>
                </div>
              )}
            </div>
          )}

          {selectedChat && (
            <div
              className="flex-1 p-4 overflow-y-auto h-[50vh]"
              ref={chatContainerRef}
            >
              {messages.length === 0 ? (
                <div className="text-center mt-4"></div>
              ) : (
                messages.map((msg, index) => {
                  const isOwnMessage = msg.sender_id === userId;
                  const messageDate = new Date(msg.created_at);
                  const previousMessageDate =
                    index > 0 ? new Date(messages[index - 1].created_at) : null;

                  let showDateSeparator = false;
                  if (
                    !previousMessageDate ||
                    messageDate.toDateString() !==
                      previousMessageDate.toDateString()
                  ) {
                    showDateSeparator = true;
                  }

                  // Format the time for each message
                  const formattedTime = messageDate.toLocaleTimeString(
                    "en-US",
                    {
                      hour: "numeric",
                      minute: "numeric",
                      hour12: true,
                    }
                  );

                  // Check if the message is a payment notification
                  const isPaymentNotification = msg.message.startsWith(
                    "PAYMENT_NOTIFICATION:"
                  );

                  return (
                    <React.Fragment key={index}>
                      {/* Date Separator */}
                      {showDateSeparator && (
                        <div className="flex justify-center my-4">
                          <div className="px-4 py-2 bg-gray-300 rounded-full text-sm font-semibold">
                            {formatDateSeparator(msg.created_at)}
                          </div>
                        </div>
                      )}

                      {/* Payment Notification Message */}
                      {isPaymentNotification ? (
                        <div className="flex justify-center my-2">
                          <div className="px-4 py-2 bg-blue-200 text-blue-800 rounded-lg text-sm font-semibold">
                            {(() => {
                              // Parse the message content
                              const parts = msg.message.split(":");
                              const houseName = parts[1];
                              const houseId = parts[2];

                              return (
                                <span>
                                  Payment has been successfully made for{" "}
                                  <strong>{houseName}</strong>.{" "}
                                  <Link
                                    to={`/propertyDetails/${houseId}`}
                                    className="text-blue-500 underline"
                                  >
                                    Click here to view details.
                                  </Link>
                                </span>
                              );
                            })()}
                          </div>
                        </div>
                      ) : (
                        // Regular Message Bubble
                        <div
                          className={`flex mb-3 ${
                            isOwnMessage ? "justify-end" : "justify-start"
                          }`}
                          ref={
                            index === messages.length - 1
                              ? lastMessageRef
                              : null
                          }
                        >
                          <div
                            className={`flex flex-col p-2 font-halant rounded-xl relative break-words w-fit max-w-[75%] ${
                              isOwnMessage
                                ? "bg-green-700 text-white"
                                : "bg-gray-700 text-white"
                            }`}
                          >
                            {/* Message Content */}
                            {msg.message && (
                              <pre className="text-xs font-halant whitespace-pre-wrap">
                                {msg.message}
                              </pre>
                            )}

                            {/* Image Message */}
                            {msg.image_url && (
                              <img
                                src={`${backendUrl2}/${msg.image_url}`}
                                alt="Chat"
                                className="mt-2 rounded-lg cursor-pointer max-w-[250px] max-h-[250px] object-cover"
                                onClick={() =>
                                  handleImageClick(
                                    `${backendUrl2}/${msg.image_url}`
                                  )
                                }
                              />
                            )}

                            {/* Timestamp */}
                            <div className="text-[9px] font-semibold text-gray-300 block mt-1 ml-2 self-end">
                              {formattedTime.toLowerCase()}
                            </div>
                          </div>
                        </div>
                      )}
                    </React.Fragment>
                  );
                })
              )}
            </div>
          )}

          {!selectedChat && (
            <div className="h-screen flex items-center justify-center">
              <div className="text-center text-xl font-halant font-semibold">
                Chat with an Agent, a Landlord/Owner of a property!
              </div>
            </div>
          )}

          {selectedChat && (
            <div className="justify-center font-halant-light w-full bottom-0 sticky z-50">
              {/* Input Bar */}
              <div className="sticky bottom-0 px-5 py-2 flex">
                <input
                  type="file"
                  id="fileInput"
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                  className=""
                />
                <button
                  onClick={openAttachmentModal}
                  className="p-2 text-gray-600 rounded-full hover:text-gray-800"
                >
                  <FaPaperclip className="text-lg" />
                </button>
                <textarea
                  ref={chatInputRef}
                  value={newMessage}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDown}
                  placeholder="Type a message..."
                  className="flex-1 ml-4 p-2 border rounded-3xl resize-none focus:outline-none focus:ring-2 focus:ring-green-600 focus:border-transparent text-sm"
                  rows={1}
                  style={{
                    maxHeight: "150px",
                    overflowY: "hidden",
                    lineHeight: "1.5",
                  }}
                />
                <button
                  onClick={handleSendMessage}
                  disabled={isSendButtonDisabled}
                  className="p-2 ml-4"
                >
                  <SendIcon />
                </button>
              </div>
              <div className="flex justify-center">
                <span className="mr-5 px-3 py-1 bg-red-700 text-white font-halant font-semibold text-base rounded-sm">
                  Dispute
                </span>
                <span
                  onClick={handleBuyClick}
                  className="px-5 py-1 bg-green-700 text-white font-halant font-semibold text-base rounded-sm cursor-pointer"
                >
                  Buy
                </span>
              </div>
              {/* Selected File Modal */}
              <Modal
                isOpen={isModalOpen}
                onRequestClose={handleRemoveFile}
                contentLabel="Selected File Preview"
                className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75"
                overlayClassName="fixed inset-0 bg-black bg-opacity-75 z-[9999]"
              >
                <div className="bg-white p-6 rounded-lg shadow-lg max-w-lg mx-auto">
                  <div className="flex justify-end mb-4">
                    <button onClick={handleRemoveFile}>
                      <FaTimes className="text-xl" />
                    </button>
                  </div>
                  {selectedFilePreview && (
                    <img
                      src={selectedFilePreview}
                      alt="Selected File"
                      width={400}
                      height={400}
                      className="mx-auto"
                    />
                  )}
                  <div className="mt-4">
                    <label
                      htmlFor="caption"
                      className="block mb-2 font-semibold"
                    >
                      Caption:
                    </label>
                    <input
                      type="text"
                      id="caption"
                      value={caption}
                      onChange={(e) => setCaption(e.target.value)}
                      className="w-full p-2 border rounded-md"
                    />
                  </div>
                  <div className="flex justify-end mt-4">
                    <button
                      onClick={handleSendMessage}
                      className="bg-blue-500 text-white p-2 rounded-md"
                    >
                      Send
                    </button>
                  </div>
                </div>
              </Modal>
              {/* Image Modal */}
              <Modal
                isOpen={imageModalOpen}
                onRequestClose={handleCloseImageModal}
                contentLabel="Image Viewer"
                className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75"
                overlayClassName="fixed inset-0 bg-black bg-opacity-75 z-[9999]"
              >
                <div className="bg-white p-6 rounded-lg shadow-lg max-w-lg mx-auto">
                  <div className="flex justify-end mb-4">
                    <button onClick={handleCloseImageModal}>
                      <FaTimes className="text-xl" />
                    </button>
                  </div>
                  {imageToView && (
                    <img
                      src={imageToView}
                      alt="Preview"
                      width={400}
                      height={400}
                      className="mx-auto"
                    />
                  )}
                </div>
              </Modal>
              {/* Attachment Modal */}
              <Modal
                isOpen={isAttachmentModalOpen}
                onRequestClose={closeAttachmentModal}
                contentLabel="Attachment Options"
                className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75"
                overlayClassName="fixed inset-0 bg-black bg-opacity-75 z-[9999]"
              >
                <div className="bg-white p-6 rounded-lg shadow-lg max-w-md mx-auto">
                  <div className="flex justify-between mb-4">
                    <h2 className="text-xl font-semibold">
                      Attachment Options
                    </h2>
                    <button onClick={closeAttachmentModal}>
                      <FaTimes className="text-xl" />
                    </button>
                  </div>
                  <div className="flex flex-col space-y-4">
                    <button
                      onClick={() => {
                        document.getElementById("fileInput").click();
                        closeAttachmentModal();
                      }}
                      className="bg-blue-500 text-white p-2 rounded-md"
                    >
                      Send File
                    </button>
                  </div>
                </div>
              </Modal>
              {/* Modal for displaying house details */}
              {/* Modal for displaying house details */}
              <Modal
                isOpen={isHouseModalOpen}
                onRequestClose={closeModal}
                contentLabel="House Purchase"
                className="modal-content w-full max-w-lg mx-auto my-10 bg-white rounded-lg shadow-lg overflow-hidden"
                overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
              >
                <div className="p-6">
                  <button
                    onClick={closeModal}
                    className="text-gray-500 hover:text-red-500 font-semibold text-xl absolute top-4 right-4"
                  >
                    &times;
                  </button>
                  <div className="modal-header mb-6">
                    <h2 className="font-halant font-bold text-2xl text-center">
                      Pending Purchases
                    </h2>
                  </div>
                  <div className="modal-body">
                    {houses.length === 0 ? (
                      <p className="text-center text-gray-500">
                        No houses available for purchase.
                      </p>
                    ) : (
                      houses.map((house) => (
                        <div
                          key={house.id}
                          className="house-item flex items-center mb-4 border-b pb-4"
                        >
                          {/* House Image */}
                          <img
                            src={`${backendUrl2}/${house.images[0]}`}
                            alt={house.house_name}
                            className="house-image w-32 h-24 object-cover rounded-md mr-4"
                          />
                          {/* House Details */}
                          <div className="house-details flex-1">
                            <h3 className="font-halant font-semibold text-lg">
                              {house.house_name}
                            </h3>
                            <p className="font-halant text-sm text-gray-500">
                              {formatPrice(house.price)}
                            </p>
                          </div>
                          {/* Action Buttons */}
                          <div className="flex flex-col space-y-2">
                            <button
                              onClick={() => handleBuyHouse(house.id)}
                              className="buy-btn bg-green-600 hover:bg-green-700 text-white font-semibold rounded-md px-5 py-2 ml-auto"
                            >
                              Buy
                            </button>
                            <button
                              onClick={() => handleRemoveHouse(house.id)}
                              className="remove-btn bg-red-600 hover:bg-red-700 text-white font-semibold rounded-md px-5 py-2 ml-auto"
                            >
                              Remove
                            </button>
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                </div>
              </Modal>

              <Modal
                isOpen={isPurchaseModalOpen}
                onRequestClose={() => setIsPurchaseModalOpen(false)}
                contentLabel="Purchase Result"
                className="modal-content w-full max-w-md mx-auto my-10 bg-white rounded-lg shadow-lg overflow-hidden"
                overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
              >
                <div className="p-6">
                  <button
                    onClick={() => setIsPurchaseModalOpen(false)}
                    className="text-gray-500 hover:text-red-500 font-semibold text-xl absolute top-4 right-4"
                  >
                    &times;
                  </button>
                  <div className="modal-header mb-6 text-center">
                    <h2 className="font-halant font-bold text-2xl">
                      Purchase Status
                    </h2>
                  </div>
                  <div className="modal-body text-center">
                    {isSuccess ? (
                      <div className="success-message flex flex-col items-center">
                        <p className="mb-4">{purchaseMessage}</p>
                        <div className="success-icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-12 w-12 text-green-600"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M5 13l4 4L19 7"
                            />
                          </svg>
                        </div>
                      </div>
                    ) : (
                      <div className="insufficient-balance-message flex flex-col items-center">
                        <p className="mb-4">{purchaseMessage}</p>
                        <div className="error-icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-12 w-12 text-red-600"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M6 18L18 6M6 6l12 12"
                            />
                          </svg>
                        </div>
                        <button
                          onClick={() => setIsFundModalOpen(true)}
                          className="w-full md:w-auto bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded-md font-semibold flex items-center justify-center space-x-2"
                        >
                          Fund Wallet
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </Modal>

              {/* Reusable FundWalletModal Component */}
              <FundWalletModal
                isOpen={isFundModalOpen}
                onClose={() => setIsFundModalOpen(false)}
              />
            </div>
          )}
        </div>
      </div>
      {/* User Profile Modal */}
      <UserProfileModal
        isOpen={isProfileModalOpen}
        onRequestClose={closeProfileModal}
        userId={selectedUserId}
      />
    </>
  );
};

export default Chat;
