import React, { useState, useEffect } from "react";
import axios from "axios";
import SearchSharpIcon from "@mui/icons-material/SearchSharp";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import Properties from "./Properties";

function Favourites() {
  const [propertyData, setPropertyData] = useState([]);
  const [loading, setLoading] = useState(true);
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  //   const type = "Shortlet";

  const [userId, setUserId] = useState("");

  useEffect(() => {
    const userId = localStorage.getItem("userId");
    if (userId) {
      setUserId(userId);

      const fetchPropertyData = async () => {
        try {
          const response = await axios.get(
            `${backendUrl}/houses/userfavorites/${userId}`
          );
          setPropertyData(response.data);
        } catch (error) {
          console.error("Error fetching property data:", error);
        } finally {
          setLoading(false);
        }
      };

      fetchPropertyData();
    }
  }, [backendUrl, userId]);

  // useEffect(() => {}, [backendUrl, userId]);

  if (loading) return <p>Loading...</p>;

  return (
    <>
      <head>
        <title>HousemeNG | Favourites</title>
      </head>
      <div className="flex flex-col md:flex-row mt-[80px]">
        {/* Map container */}
        {/* Properties */}
        <div className="w-full md:z-0 z-[1001] md:mx-[2%]">
          <div className="mt-7">
            <div className="mx-7 md:mx-0 bg-white md:bg-none pt-5 px-5 md:pt-0 md:px-0 rounded-3xl">
              <div className="mb-5">
                <h1 className="sm:text-3xl font-halant text-xl font-bold">
                  Search Properties
                </h1>
                <h2 className="text-gray-500 font-halant font-semibold text-sm sm:text-base">
                  You liked {propertyData.length} properties
                </h2>
              </div>
              <div className="border border-gray-200 md:hidden"></div>
              <div className="bg-white rounded-lg p-3 flex flex-col sm:flex-row items-center space-x-3 space-y-3 sm:space-y-0">
                <div className="flex space-x-3">
                  <div className="relative flex items-center">
                    <LocationOnOutlinedIcon className="text-gray-500 absolute ml-2" />
                    <input
                      type="text"
                      placeholder="Location"
                      className="pl-8 px-4 py-2 font-manrope font-semibold border-2 rounded-md bg-white text-black border-gray-300 w-[130px]"
                    />
                  </div>
                  <select
                    name="priceRange"
                    id="priceRange"
                    placeholder="Price Range"
                    className="px-4 py-2 border-2 font-manrope font-semibold rounded-md bg-white text-gray-500 border-gray-300 w-[130px]"
                  >
                    <option>Price Range</option>
                    <option>Option 1</option>
                    <option>Option 2</option>
                  </select>
                </div>
                <div className="flex space-x-3">
                  <div className="flex">
                    <div className="relative">
                      <button className="bg-green-700 font-halant font-semibold text-white text-lg px-6 py-2 rounded-3xl pr-10">
                        Search
                      </button>
                      <SearchSharpIcon className="text-white absolute right-3 top-1/2 transform -translate-y-1/2" />
                    </div>
                  </div>
                  <div className="bg-[#d0f4d8] py-1 px-2 rounded-lg hidden lg:block">
                    <TuneOutlinedIcon />
                  </div>
                </div>
              </div>
              <div className="border border-gray-200 mb-5 md:hidden"></div>
            </div>
            <div>
              <Properties properties={propertyData} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Favourites;
