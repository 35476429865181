import { Link, useParams, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import {
  KeyboardArrowLeftOutlined,
  FavoriteBorderRounded,
  Favorite,
} from "@mui/icons-material";
import {
  FaBed,
  FaShower,
  FaRulerCombined,
  FaCheckSquare,
} from "react-icons/fa";
import { MdGarage } from "react-icons/md";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import avatar from "../assets/images/avatar.png";
import UserProfileModal from "./reusableComponents/UserProfileModal";

function PropertyDetails() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [property, setProperty] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const backendUrl2 = process.env.REACT_APP_BACKEND_URL2;

  const [userId, setUserId] = useState("");
  const [isFavorite, setIsFavorite] = useState(false);

  const [email, setEmail] = useState("");

  // New State Variables
  const [ownerData, setOwnerData] = useState(null);

  // **New State Variables for Image Modal**
  const [imageModalVisible, setImageModalVisible] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  // State variables for the profile modal
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);

  useEffect(() => {
    const storedUserId = localStorage.getItem("userId");
    if (storedUserId) {
      setUserId(storedUserId);
    }
  }, []);

  useEffect(() => {
    const storedEmail = localStorage.getItem("email");
    if (storedEmail) {
      setEmail(storedEmail);
    }
  }, []);

  useEffect(() => {
    const fetchProperty = async () => {
      try {
        const response = await axios.get(`${backendUrl}/houses/${id}`);
        setProperty(response.data);
      } catch (err) {
        setError("Property not found");
      } finally {
        setLoading(false);
      }
    };

    fetchProperty();
  }, [id, backendUrl]);

  useEffect(() => {
    const checkFavoriteStatus = async () => {
      try {
        const response = await axios.get(
          `${backendUrl}/houses/favorites/${userId}/${id}`
        );
        setIsFavorite(response.data.isFavorite);
      } catch (error) {
        console.error("Error checking favorite status:", error);
      }
    };

    if (userId) {
      checkFavoriteStatus();
    }
  }, [userId, id, backendUrl]);

  const toggleFavorite = async () => {
    try {
      if (isFavorite) {
        await axios.delete(`${backendUrl}/houses/favorites`, {
          data: { userId, propertyId: id },
        });
        setIsFavorite(false);
        toast.info("Removed from favorites");
      } else {
        await axios.post(`${backendUrl}/houses/favorites`, {
          userId,
          propertyId: id,
        });
        setIsFavorite(true);
        toast.success("Added to favorites");
      }
    } catch (error) {
      console.error("Error updating favorite status:", error);
      toast.error("Failed to update favorite status");
    }
  };

  const handleSendMessage = () => {
    if (property && property.user_id) {
      const randomString = Math.random().toString(36).substring(2, 7);
      const sessionId = `${id}-${randomString}`;
      const chatUrl = `/chat/${userId}/${property.user_id}?session_id=${sessionId}`;
      navigate(chatUrl);
    } else {
      console.error("Owner ID not found");
    }
  };

  // Fetch Owner Data
  useEffect(() => {
    const fetchOwnerData = async () => {
      try {
        const response = await axios.get(
          `${backendUrl}/users/users/${property.user_id}`
        );
        setOwnerData(response.data);
      } catch (error) {
        console.error("Error fetching owner data:", error);
      }
    };

    if (property && property.user_id) {
      fetchOwnerData();
    }
  }, [property, backendUrl]);

  // **Functions to Open and Close Image Modal**
  const openImageModal = (imageUrl) => {
    setSelectedImage(imageUrl);
    setImageModalVisible(true);
  };

  const closeImageModal = () => {
    setSelectedImage(null);
    setImageModalVisible(false);
  };

  useEffect(() => {
    document.body.style.overflow = imageModalVisible ? "hidden" : "auto";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [imageModalVisible]);

  // **Determine the Listing Page Based on the Property's Tag**
  let listingPage = "/buy";
  if (property && property.tag) {
    const tagLower = property.tag.toLowerCase();
    if (["buy", "rent", "shortlet"].includes(tagLower)) {
      listingPage = `/${tagLower}`;
    }
  }

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <p className="text-gray-600 text-xl">Loading property details...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-screen">
        <p className="text-red-500 text-xl">{error}</p>
      </div>
    );
  }

  // Slider settings
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: property.images.length >= 4 ? 4 : property.images.length,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1280, // Tailwind's xl breakpoint
        settings: {
          slidesToShow:
            property.images.length >= 3 ? 3 : property.images.length,
        },
      },
      {
        breakpoint: 1024, // Tailwind's lg breakpoint
        settings: {
          slidesToShow:
            property.images.length >= 2 ? 2 : property.images.length,
        },
      },
      {
        breakpoint: 640, // Tailwind's sm breakpoint
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "40px",
        },
      },
    ],
  };

  return (
    <>
      <head>
        <title>HousemeNG | Property Details</title>
      </head>
      <div className="mt-[111px] font-halant">
        <ToastContainer />
        <div className="mx-[5.5%]">
          <div className="mb-5 flex items-center">
            <Link to={listingPage}>
              <button className="bg-[#d0f4d8] rounded-lg flex py-2 px-3 items-center">
                <KeyboardArrowLeftOutlined className="mr-2" />
                <p className="font-semibold text-lg">Go to Listings</p>
              </button>
            </Link>
          </div>
        </div>

        {/* Header */}
        <div className="px-[5.5%] mb-7 flex items-center justify-between">
          <div>
            <h1 className="text-2xl font-bold">{property.house_name}</h1>
            <p className="text-lg text-gray-600">
              {property.location}, {property.state}
            </p>
          </div>
          <button
            className="bg-green-700 text-white px-4 py-2 rounded-md flex items-center"
            onClick={toggleFavorite}
            disabled={userId === property.user_id}
            title={
              userId === property.user_id
                ? "You can't add your own property to favorites"
                : ""
            }
          >
            {isFavorite ? <Favorite /> : <FavoriteBorderRounded />}
            <span className="ml-2 hidden sm:block">
              {isFavorite ? "Remove from Favorites" : "Add to Favorites"}
            </span>
          </button>
        </div>

        {/* Images Carousel */}
        <div className="mb-8 px-[5.5%]">
          <Slider {...sliderSettings}>
            {property.images &&
              property.images.map((image, index) => (
                <div key={index} className="px-2">
                  <div
                    className="w-full h-[300px] overflow-hidden border-green-200 border-2 rounded-xl cursor-pointer"
                    onClick={() => openImageModal(`${backendUrl2}/${image}`)}
                  >
                    <img
                      src={`${backendUrl2}/${image}`}
                      alt={property.house_name}
                      className="w-full h-full object-cover rounded-lg shadow-md"
                    />
                  </div>
                </div>
              ))}
          </Slider>
        </div>

        {/* **Image Modal with Clickaway Listener** */}
        {imageModalVisible && selectedImage && (
          <div
            className="fixed inset-0 z-[9999] flex items-center justify-center bg-black bg-opacity-75"
            onClick={closeImageModal} // Click on backdrop closes the modal
          >
            <div
              className="relative bg-none rounded-lg p-4 max-w-3xl w-full"
              onClick={(e) => e.stopPropagation()} // Prevent click inside modal from closing
            >
              <img
                src={selectedImage}
                alt="Full View"
                className="w-full h-auto object-contain rounded"
              />
            </div>
          </div>
        )}

        {/* Main Content */}
        <div className="flex flex-col lg:flex-row px-[5.5%] py-4 space-y-6 lg:space-y-0 lg:space-x-6">
          <div className="lg:w-2/3">
            {/* Property Details */}
            <div>
              <div className="flex items-center justify-between py-5 px-7 border border-gray-200 shadow-sm rounded-lg mb-6">
                <div className="text-center">
                  <FaBed
                    size={20}
                    className="text-green-700"
                    aria-hidden="true"
                  />
                  <p className="text-lg font-bold">
                    {property.beds}{" "}
                    <span className="hidden sm:inline-flex">Beds</span>
                  </p>
                </div>
                <div className="text-center">
                  <FaShower
                    size={20}
                    className="text-green-700"
                    aria-hidden="true"
                  />{" "}
                  <p className="text-lg font-bold">
                    {property.baths}{" "}
                    <span className="hidden sm:inline-flex">Baths</span>
                  </p>
                </div>
                <div className="text-center">
                  <MdGarage
                    size={20}
                    className="text-green-700"
                    aria-hidden="true"
                  />{" "}
                  <p className="text-lg font-bold">
                    {property.garages}{" "}
                    <span className="hidden sm:inline-flex">Garages</span>
                  </p>
                </div>
                <div className="text-center">
                  <FaRulerCombined
                    size={20}
                    className="text-green-700"
                    aria-hidden="true"
                  />{" "}
                  <p className="text-lg font-bold">
                    {property.land_area}{" "}
                    <span className="hidden sm:inline-flex">sqft</span>
                  </p>
                </div>
                <div className="text-center">
                  <FaCheckSquare size={20} className="text-green-700" />
                  <p className="text-lg font-bold">{property.tag}</p>
                </div>
              </div>
              {/* About Property */}
              <div className="mb-6">
                <h2 className="text-2xl font-semibold mb-4">
                  About this property
                </h2>
                <p className="text-gray-700 text-lg">{property.description}</p>
              </div>
            </div>
          </div>

          {/* Sidebar */}
          <div className="lg:w-1/3">
            <div className="p-6 border border-gray-200 rounded-lg">
              <div className="mb-6">
                <h1 className="text-xl font-semibold text-gray-600">Price</h1>
                <div className="flex items-baseline">
                  <p className="text-3xl font-bold text-green-700">
                    ₦{parseInt(property.price).toLocaleString()}
                  </p>
                  <span className="text-red-700 ml-2 text-lg font-bold">
                    (excluding charges)
                  </span>
                </div>
              </div>
              <button
                className="bg-green-700 text-white w-full py-3 rounded-md font-semibold"
                onClick={handleSendMessage}
                disabled={userId === property.user_id}
                title={
                  userId === property.user_id
                    ? "You can't message yourself about this property"
                    : ""
                }
              >
                Open Escrow
              </button>

              <div className="border-t border-gray-200 my-6"></div>

              {/* Owner Details */}
              <div className="mt-5">
                <h1 className="text-gray-600 font-semibold font-halant text-xl">
                  Listed by:
                </h1>
              </div>
              <div className="mt-4 flex items-center justify-between">
                <div className="flex items-center">
                  <img
                    src={
                      ownerData && ownerData.avatar
                        ? `${backendUrl2}/${ownerData.avatar}`
                        : avatar
                    }
                    alt="Owner Avatar"
                    className="w-14 h-13 rounded-md mr-4 cursor-pointer"
                    onClick={() => {
                      if (ownerData && ownerData.unique_id) {
                        setSelectedUserId(ownerData.unique_id);
                        setIsProfileModalOpen(true);
                      }
                    }}
                  />
                  <div className="">
                    <h3 className="text-md lg:text-lg font-halant font-semibold">
                      {ownerData && ownerData.title
                        ? `${ownerData.title} ${ownerData.firstname} ${ownerData.lastname}`
                        : `${property.lister_title} ${property.poster_name}`}
                    </h3>
                    <p className="text-gray-500 font-halant-light">
                      Property Owner
                    </p>
                  </div>
                </div>
                {/* More Info Button */}
                <div>
                  <button
                    className="bg-[#d0f4d8] text-black font-semibold px-3 py-2 rounded-md text-sm flex space-x-1"
                    onClick={() => {
                      if (ownerData && ownerData.unique_id) {
                        setSelectedUserId(ownerData.unique_id);
                        setIsProfileModalOpen(true);
                      }
                    }}
                  >
                    <InfoOutlinedIcon />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Profile Modal */}
        {isProfileModalOpen && selectedUserId && (
          <UserProfileModal
            isOpen={isProfileModalOpen}
            onRequestClose={() => setIsProfileModalOpen(false)}
            userId={selectedUserId}
          />
        )}
      </div>
    </>
  );
}

export default PropertyDetails;
