import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import LockTwoToneIcon from "@mui/icons-material/LockTwoTone";
import axios from "axios";

function NewPassword() {
  const { token } = useParams();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const navigate = useNavigate();

  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/users/reset-password`,
        {
          token,
          newPassword: password,
        }
      );
      setSuccess("Password updated successfully!");
      setError("");
      // console.log("Password Reset Successful", response.data);
      navigate("/login");
    } catch (err) {
      setError("Failed to update password. Please try again.");
      setSuccess("");
    }
  };

  return (
    <>
    <head>
        <title>HousemeNG | New Password</title>
      </head>
    <div className="flex justify-center items-center h-screen bg-gray-100 overflow-y-hidden">
      <div className="w-full h-screen bg-white">
        <div className="w-full">
          <div className="items-center h-screen justify-center flex flex-col">
            <div className="flex justify-center">
              <div className="p-3 border border-gray-400 rounded-xl items-center">
                <LockTwoToneIcon />
              </div>
            </div>
            <div className="mb-4">
              <h2 className="text-3xl font-halant font-bold mb-1 text-center ">
                Set New Password
              </h2>
              <p className="text-gray-500 text-center font-halant-light text-lg">
                Your new password must be different <br /> to previously used
                passwords.
              </p>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="mb-4 w-96 px-7">
                <label
                  className="block text-gray-700 font-halant text-lg font-bold mb-2"
                  htmlFor="password"
                >
                  New Password
                </label>
                <input
                  className="shadow-md appearance-none border rounded py-2 px-3 w-full text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-green-700 focus:border-transparent"
                  id="password"
                  type="password"
                  placeholder="Enter your new password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div className="mb-4 w-96 px-7">
                <label
                  className="block text-gray-700 font-halant text-lg font-bold mb-2"
                  htmlFor="confirmPassword"
                >
                  Confirm password
                </label>
                <input
                  className="shadow-md font-halant-light appearance-none border rounded py-2 px-3 w-full text-gray-700 leading-tight focus:outline-none focus:ring-2 focus:ring-green-700 focus:border-transparent"
                  id="confirmPassword"
                  type="password"
                  placeholder="Confirm your new password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </div>
              {error && <p className="error-message text-red-500">{error}</p>}
              {success && (
                <p className="success-message text-green-500">{success}</p>
              )}
              <div className="text-center mt-7 mb-5">
                <button
                  type="submit"
                  className="bg-green-700 hover:bg-green-900 text-white font-halant text-lg font-bold py-3 px-24 rounded-full focus:outline-none focus:shadow-outline"
                >
                  Reset password
                </button>
              </div>
              <div className="flex items-center justify-center">
                <Link
                  to="/login"
                  className="align-baseline font-bold text-md text-green-700 hover:text-green-900 flex"
                >
                  <ChevronLeftOutlinedIcon className="mt-[1px]" />
                  <p className="text-center font-halant font-bold text-lg">
                    Back to log in
                  </p>
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default NewPassword;
