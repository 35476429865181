import { Outlet, useLocation, useMatch } from "react-router-dom";
import Navbar from "./reusableComponents/Navbar";
import Footer from "./reusableComponents/Footer";

function Layout() {
  const location = useLocation();

  // Check if the route is a match for the chat route
  const matchChatRoute = useMatch("/chat/:user1/:user2");

  // Define routes that should hide the footer
  const noFooterRoutes = [
    "/signup",
    "/login",
    "/forgotPassword",
    "/newPassword",
    "/sell",
    "/favourites",
    "/kycadmin",
    "/userDashboard",
    "/profile",
    "/support",
    "/manage-houses",
    "/settings",
    "/docUpload",
    "/personalInfo",
    "/notifications",
  ];

  // Define routes that should hide the navbar
  const noNavbarRoutes = [];

  // Check if the current pathname matches a no-footer route
  const isNoFooterRoute = noFooterRoutes.some((route) =>
    location.pathname.startsWith(route)
  );

  // Check if the current pathname matches a no-navbar route
  const isNoNavbarRoute = noNavbarRoutes.some((route) =>
    location.pathname.startsWith(route)
  );

  return (
    <div className="flex flex-col min-h-screen">
      {/* Only render Navbar if the current route is not in the noNavbarRoutes */}
      {!isNoNavbarRoute && <Navbar />}

      <main className="flex-grow">
        <Outlet />
      </main>

      {/* Hide Footer for chat route or noFooterRoutes */}
      {!matchChatRoute && !isNoFooterRoute && <Footer />}
    </div>
  );
}

export default Layout;
