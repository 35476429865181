import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios"; // Ensure axios is imported

const Sell = () => {
  const [verificationStatus, setVerificationStatus] = useState(null); // To store user's verification status
  const [verificationReason, setVerificationReason] = useState(""); // To store the reason if verification is denied
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const [userId, setUserId] = useState("");

  useEffect(() => {
    const userId = localStorage.getItem("userId");
    if (userId) {
      setUserId(userId);
    }
  }, []);

  // Fetch verification status and reason when component mounts
  useEffect(() => {
    const fetchVerificationStatus = async () => {
      try {
        const response = await axios.get(
          `${backendUrl}/users/verification/${userId}`
        );
        const { verification, reason } = response.data;
        setVerificationStatus(verification); // Store the verification status
        setVerificationReason(reason || ""); // Store the verification denial reason if it exists
      } catch (error) {
        console.error("Error fetching verification status:", error);
      }
    };

    if (userId) {
      fetchVerificationStatus();
    }
  }, [backendUrl, userId]);

  return (
    <>
     <head>
        <title>HousemeNG | Sell</title>
      </head>
    <div className="items-center h-screen sm:px-[20%] px-[10%] flex font-halant">
      <div className="bg-white p-6 text-center">
        <h2 className="sm:text-3xl text-2xl font-halant font-bold mb-4">
          Become a Seller?
        </h2>
        <p className="mb-6 sm:text-xl text-base text-justify font-halant-light">
          Please note that you must complete the Know Your Customer (KYC)
          verification process to become a seller & an important part of our
          platform. Verification ensures a secure and trusted marketplace for
          all users.
        </p>

        {/* Conditional rendering based on verification status */}
        {verificationStatus === 0 || verificationStatus === 2 ? (
          <>
            {verificationStatus === 2 && (
              <>
                <button className="bg-red-400 text-white font-halant text-lg font-semibold py-3 px-11 rounded-full mb-2">
                  Verification Denied
                </button>
                <hr className="border-black" />

                <p className="my-5 text-base text-black">
                  Reason: <span></span>
                  {verificationReason
                    ? verificationReason
                    : "No reason provided."}
                </p>
                <hr className="border-black" />
                <Link to="/personalInfo">
                  <button className="bg-green-700 hover:bg-green-800 text-white font-halant text-lg font-semibold py-3 px-11 rounded-full mt-2">
                    Re-Verify
                  </button>
                </Link>
              </>
            )}
            {verificationStatus === 0 && (
              <Link to="/personalInfo">
                <button className="bg-green-700 hover:bg-green-800 text-white font-halant text-lg font-semibold py-3 px-11 rounded-full">
                  Get Started
                </button>
              </Link>
            )}
          </>
        ) : verificationStatus === 1 ? (
          <button className="bg-gray-400 text-white font-halant text-lg font-semibold py-3 px-11 rounded-full">
            Verification Pending
          </button>
        ) : verificationStatus === 3 ? (
          <Link to="/addProperties">
            <button className="bg-green-700 hover:bg-green-800 text-white font-halant text-lg font-semibold py-3 px-11 rounded-full">
              Sell
            </button>
          </Link>
        ) : (
          // You can display a loader or some placeholder while waiting for the status
          <p>Loading...</p>
        )}
      </div>
    </div>
    </>
  );
};

export default Sell;
