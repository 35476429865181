// src/components/EditHouse.js

import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { FiTrash2 } from "react-icons/fi";
import { useDropzone } from "react-dropzone";
import { FaTimes } from "react-icons/fa"; // Import FaTimes for close button

const EditHouse = () => {
  const { id } = useParams(); // Getting id from URL
  const navigate = useNavigate(); // Navigation for redirect after saving
  const [existingImages, setExistingImages] = useState([]); // Existing images

  const [imagesToDelete, setImagesToDelete] = useState([]);
  const [newImages, setNewImages] = useState([]);

  const [userId, setUserId] = useState("");

  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const backendUrl2 = process.env.REACT_APP_BACKEND_URL2;

  // **New State Variable for Confirmation Box**
  const [editConfirmationVisible, setEditConfirmationVisible] = useState(false);

  useEffect(() => {
    const storedUserId = localStorage.getItem("userId");
    if (storedUserId) {
      setUserId(storedUserId);
    } else {
      alert("User not authenticated. Please log in.");
      navigate("/login"); // Redirect to login if not authenticated
    }

    // Fetch house details based on id
    axios
      .get(`${backendUrl}/houses/edit-houses/${id}`)
      .then((response) => {
        const houseData = response.data;
        setFormData({
          propertyTitle: houseData.house_name || "",
          propertyAddress: houseData.address || "",
          aboutProperty: houseData.description || "",
          listerTitle: houseData.lister_title || "",
          propertyLocation: houseData.location || "",
          state: houseData.state || "",
          price: houseData.price || "",
          tag: houseData.tag || "",
          bedrooms: houseData.beds || 0,
          bathrooms: houseData.baths || 0,
          carParks: houseData.garages || 0,
          dimensions: houseData.land_area || 0,
        });
        setExistingImages(houseData.images || []); // Set existing images
      })
      .catch((error) => {
        console.error("Error fetching house details:", error);
        alert("Failed to load property details. Please try again.");
      });
  }, [id, backendUrl, navigate]);

  const [formData, setFormData] = useState({
    propertyTitle: "",
    propertyAddress: "",
    aboutProperty: "",
    listerTitle: "",
    propertyLocation: "",
    state: "",
    price: "",
    tag: "",
    bedrooms: 0,
    bathrooms: 0,
    carParks: 0,
    dimensions: 0,
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  // Mark image for deletion
  const removeExistingImage = (imagePath) => {
    setImagesToDelete((prev) => [...prev, imagePath]);
    setExistingImages((prevImages) =>
      prevImages.filter((image) => image !== imagePath)
    );
  };

  // Handle new image selection using React Dropzone
  const onDrop = useCallback((acceptedFiles) => {
    setNewImages((prevImages) => [...prevImages, ...acceptedFiles]);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/*",
  });

  // Remove new image
  const removeNewImage = (index) => {
    setNewImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  // Handle form data change
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Additional validation can be added here
    setFormData({ ...formData, [name]: value });
  };

  // **Function to Open Confirmation Box**
  const openEditConfirmation = () => {
    setEditConfirmationVisible(true);
  };

  // **Function to Close Confirmation Box**
  const closeEditConfirmation = () => {
    setEditConfirmationVisible(false);
    navigate("/manage-houses"); // Redirect after closing the confirmation box
  };

  // Handle form submission for updating house details
  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsSubmitting(true);

    // Prepare the data to be sent to the backend
    const data = new FormData();
    data.append("propertyTitle", formData.propertyTitle);
    data.append("propertyAddress", formData.propertyAddress);
    data.append("aboutProperty", formData.aboutProperty);
    data.append("listerTitle", formData.listerTitle);
    data.append("propertyLocation", formData.propertyLocation);
    data.append("state", formData.state);
    data.append("price", formData.price);
    data.append("tag", formData.tag);
    data.append("bedrooms", formData.bedrooms);
    data.append("bathrooms", formData.bathrooms);
    data.append("carParks", formData.carParks);
    data.append("dimensions", formData.dimensions);
    data.append("userId", userId);

    // Append new images
    newImages.forEach((image) => {
      data.append("newImages", image);
    });

    // Append images to delete
    data.append("imagesToDelete", JSON.stringify(imagesToDelete));

    try {
      // Send updated house data to the backend
      const response = await axios.put(
        `${backendUrl}/houses/edit-houses/${id}`,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("Property updated successfully", response.data);

      // **Show Confirmation Box Instead of Alert**
      openEditConfirmation();
    } catch (error) {
      console.error("Error updating property:", error);
      alert("Failed to update property. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <head>
        <title>HousemeNG | Edit Properties</title>
      </head>
      <div className="font-halant container mx-auto px-6 py-8 mt-24">
        <h2 className="text-3xl font-semibold mb-6 text-gray-800">
          Edit Property
        </h2>
        <p className="text-lg mb-6 text-gray-600">
          Update your property details below.
        </p>
        <div className="bg-white rounded-lg p-6">
          <form onSubmit={handleSubmit}>
            {/* Property Title */}
            <div className="mb-6">
              <label
                htmlFor="propertyTitle"
                className="block text-gray-700 font-semibold mb-2"
              >
                Property Title<span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                id="propertyTitle"
                name="propertyTitle"
                value={formData.propertyTitle}
                onChange={handleInputChange}
                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                required
              />
            </div>

            {/* Property Address */}
            <div className="mb-6">
              <label
                htmlFor="propertyAddress"
                className="block text-gray-700 font-semibold mb-2"
              >
                Property Address<span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                id="propertyAddress"
                name="propertyAddress"
                value={formData.propertyAddress}
                onChange={handleInputChange}
                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                required
              />
            </div>

            {/* About Property */}
            <div className="mb-6">
              <label
                htmlFor="aboutProperty"
                className="block text-gray-700 font-semibold mb-2"
              >
                About The Property<span className="text-red-500">*</span>
              </label>
              <textarea
                id="aboutProperty"
                name="aboutProperty"
                value={formData.aboutProperty}
                onChange={handleInputChange}
                rows="5"
                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                required
              ></textarea>
            </div>

            {/* Existing Images */}
            <div className="mb-8">
              <label className="block text-gray-700 font-semibold mb-2">
                Existing Images
              </label>
              <div className="flex flex-wrap gap-4">
                {existingImages.length > 0 ? (
                  existingImages.map((image, index) => {
                    // Sanitize the image path
                    const sanitizedImagePath = image.startsWith("undefined/")
                      ? image.replace("undefined/", "")
                      : image;

                    return (
                      <div key={index} className="relative w-32 h-32">
                        <img
                          src={`${backendUrl2}/${sanitizedImagePath}`}
                          alt="Existing"
                          className="w-full h-full object-cover rounded-md"
                        />
                        <button
                          type="button"
                          onClick={() =>
                            removeExistingImage(sanitizedImagePath)
                          }
                          className="absolute top-1 right-1 bg-red-500 text-white rounded-full h-6 w-6 flex items-center justify-center focus:outline-none"
                          aria-label="Remove image"
                        >
                          <FiTrash2 size={14} />
                        </button>
                      </div>
                    );
                  })
                ) : (
                  <p className="text-gray-500">No existing images.</p>
                )}
              </div>
            </div>

            {/* New Images */}
            <div className="mb-8">
              <label className="block text-gray-700 font-semibold mb-2">
                Add New Images
              </label>
              <div
                {...getRootProps()}
                className="flex items-center justify-center border-2 border-dashed border-gray-300 rounded-md cursor-pointer hover:border-green-500 py-6"
              >
                <input
                  {...getInputProps()}
                  type="file"
                  id="newImages"
                  multiple
                  accept="image/*"
                />
                <p className="text-gray-500">
                  Drag & drop images here, or click to select files
                </p>
              </div>
              <div className="flex flex-wrap gap-4 mt-4">
                {newImages.map((image, index) => (
                  <div key={index} className="relative w-32 h-32">
                    <img
                      src={URL.createObjectURL(image)}
                      alt="New Preview"
                      className="w-full h-full object-cover rounded-md"
                    />
                    <button
                      type="button"
                      onClick={() => removeNewImage(index)}
                      className="absolute top-1 right-1 bg-red-500 text-white rounded-full h-6 w-6 flex items-center justify-center focus:outline-none"
                      aria-label="Remove image"
                    >
                      <FiTrash2 size={14} />
                    </button>
                  </div>
                ))}
              </div>
            </div>

            {/* Form Grid */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {/* Lister Title */}
              <div>
                <label
                  htmlFor="listerTitle"
                  className="block text-gray-700 font-semibold mb-2"
                >
                  Title of Lister<span className="text-red-500">*</span>
                </label>
                <select
                  id="listerTitle"
                  name="listerTitle"
                  value={formData.listerTitle}
                  onChange={handleInputChange}
                  className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                  required
                >
                  <option value="">Select Title</option>
                  <option value="Agent">Agent</option>
                  <option value="Landlord/Owner">Landlord/Owner</option>
                </select>
              </div>

              {/* Property Location */}
              <div>
                <label
                  htmlFor="propertyLocation"
                  className="block text-gray-700 font-semibold mb-2"
                >
                  Property Location<span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  id="propertyLocation"
                  name="propertyLocation"
                  value={formData.propertyLocation}
                  onChange={handleInputChange}
                  className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                  required
                />
              </div>

              {/* State */}
              <div>
                <label
                  htmlFor="state"
                  className="block text-gray-700 font-semibold mb-2"
                >
                  State<span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  id="state"
                  name="state"
                  value={formData.state}
                  onChange={handleInputChange}
                  className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                  required
                />
              </div>

              {/* Price */}
              <div>
                <label
                  htmlFor="price"
                  className="block text-gray-700 font-semibold mb-2"
                >
                  Price (₦)<span className="text-red-500">*</span>
                </label>
                <input
                  type="number"
                  id="price"
                  name="price"
                  value={formData.price}
                  onChange={handleInputChange}
                  min="0"
                  className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                  required
                />
              </div>

              {/* Tag */}
              <div>
                <label
                  htmlFor="tag"
                  className="block text-gray-700 font-semibold mb-2"
                >
                  Tag<span className="text-red-500">*</span>
                </label>
                <select
                  id="tag"
                  name="tag"
                  value={formData.tag}
                  onChange={handleInputChange}
                  className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                  required
                >
                  <option value="">Select Tag</option>
                  <option value="Sale">Sale</option>
                  <option value="Rent">Rent</option>
                  <option value="Shortlet">Shortlet</option>
                  <option value="Co-working">Co-working Space</option>
                  <option value="Warehouse">Warehouse</option>
                  <option value="EventCentre">Event Centre</option>
                  <option value="Office">Office</option>
                </select>
              </div>

              {/* Bedrooms */}
              <div>
                <label
                  htmlFor="bedrooms"
                  className="block text-gray-700 font-semibold mb-2"
                >
                  Bedrooms
                </label>
                <input
                  type="number"
                  id="bedrooms"
                  name="bedrooms"
                  value={formData.bedrooms}
                  onChange={handleInputChange}
                  min="0"
                  className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                />
              </div>

              {/* Bathrooms */}
              <div>
                <label
                  htmlFor="bathrooms"
                  className="block text-gray-700 font-semibold mb-2"
                >
                  Bathrooms
                </label>
                <input
                  type="number"
                  id="bathrooms"
                  name="bathrooms"
                  value={formData.bathrooms}
                  onChange={handleInputChange}
                  min="0"
                  className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                />
              </div>

              {/* Car Parks */}
              <div>
                <label
                  htmlFor="carParks"
                  className="block text-gray-700 font-semibold mb-2"
                >
                  Car Parks
                </label>
                <input
                  type="number"
                  id="carParks"
                  name="carParks"
                  value={formData.carParks}
                  onChange={handleInputChange}
                  min="0"
                  className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                />
              </div>

              {/* Dimensions */}
              <div>
                <label
                  htmlFor="dimensions"
                  className="block text-gray-700 font-semibold mb-2"
                >
                  Dimensions (sq ft)
                </label>
                <input
                  type="number"
                  id="dimensions"
                  name="dimensions"
                  value={formData.dimensions}
                  onChange={handleInputChange}
                  min="0"
                  className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-green-500"
                />
              </div>
            </div>

            {/* Submit Button */}
            <div className="mt-8 flex justify-end">
              <button
                type="submit"
                className={`w-full md:w-auto bg-green-500 hover:bg-green-600 text-white py-2 px-6 rounded-md font-semibold flex items-center justify-center ${
                  isSubmitting ? "opacity-50 cursor-not-allowed" : ""
                }`}
                disabled={isSubmitting}
              >
                {isSubmitting ? "Saving..." : "Save Changes"}
              </button>
            </div>
          </form>
        </div>

        {/* **Confirmation Box** */}
        {editConfirmationVisible && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white rounded-lg shadow-lg p-6 max-w-md w-full relative">
              {/* Close Button */}
              <button
                className="absolute top-3 right-3 text-gray-600 hover:text-gray-800"
                onClick={closeEditConfirmation}
                aria-label="Close confirmation box"
              >
                <FaTimes size={20} />
              </button>
              {/* Confirmation Message */}
              <h3 className="text-xl font-semibold mb-4">Success!</h3>
              <p className="mb-6">Property updated successfully.</p>
              <div className="flex justify-end">
                <button
                  onClick={closeEditConfirmation}
                  className="bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded-md"
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default EditHouse;
