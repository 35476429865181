// src/components/Notifications.js

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Modal from "react-modal";
import { useAuth } from "./AuthContext";
import defaultAvatar from "../assets/images/avatar.png"; // Adjust the path as needed
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

Modal.setAppElement("#root"); // For accessibility

const Notifications = () => {
  const { isLoggedIn } = useAuth();
  const [notifications, setNotifications] = useState([]);
  const [selectedNotification, setSelectedNotification] = useState(null);
  const [unreadCount, setUnreadCount] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);

  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  // Fetch notifications when component mounts or user logs in
  useEffect(() => {
    if (isLoggedIn) {
      const fetchNotifications = async () => {
        try {
          const response = await axios.get(`${backendUrl}/notifications`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`, // Adjust if your token is stored differently
            },
          });
          setNotifications(response.data.notifications);
          const unread = response.data.notifications.filter(
            (n) => !n.read
          ).length;
          setUnreadCount(unread);
        } catch (error) {
          console.error("Error fetching notifications:", error);
        }
      };

      fetchNotifications();
    }
  }, [isLoggedIn, backendUrl]);

  const handleNotificationClick = (notification) => {
    setSelectedNotification(notification);
    setIsDetailModalOpen(true);
    // Optionally mark as read
    if (!notification.read) {
      markNotificationAsRead(notification.id);
    }
  };

  const markNotificationAsRead = async (notificationId) => {
    try {
      await axios.post(
        `${backendUrl}/notifications/mark-as-read`,
        { notificationId },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      // Update local state
      setNotifications((prev) =>
        prev.map((notif) =>
          notif.id === notificationId ? { ...notif, read: true } : notif
        )
      );
      setUnreadCount((prev) => prev - 1);
    } catch (error) {
      console.error("Error marking notification as read:", error);
    }
  };

  // Derived state for filtered notifications based on search term
  const filteredNotifications = notifications.filter((notification) =>
    notification.message.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    document.body.style.overflow = "hidden"; // Disable body scrolling
    return () => {
      document.body.style.overflow = "auto"; // Restore scrolling when the component is unmounted
    };
  }, []);

  return (
    <>
     <head>
        <title>HousemeNG | Notifications</title>
      </head>
    <div className="flex overflow-y-hidden flex-col h-screen mt-32 px-11 font-halant">
      {/* Header with Search Bar */}
      <div className="flex flex-col items-center mb-4">
        {/* Notifications Heading */}
        <div className="flex flex-col mb-7">
        <h1 className="font-bold text-2xl text-center mb-2">
          Notifications
        </h1>
        <div className="border border-green-500 w-40 -mt-2"></div>
        </div>

        {/* Search Bar Container */}
        <div className="relative w-full sm:w-[300px] sm:self-start">
          <input
            type="text"
            placeholder="Search Notifications..."
            className="w-full p-3 pl-10 pr-4 border rounded-full focus:outline-none focus:ring-2 focus:ring-green-600"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          {/* Search Icon */}
          <span className="absolute inset-y-0 left-0 flex items-center pl-3">
            <svg
              className="w-5 h-5 text-gray-400"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M12.9 14.32a8 8 0 111.414-1.414l4.387 4.387a1 1 0 01-1.414 1.414l-4.387-4.387zM8 14a6 6 0 100-12 6 6 0 000 12z"
                clipRule="evenodd"
              />
            </svg>
          </span>
        </div>
      </div>

      {/* Notifications List */}
      <div className="flex-1 overflow-y-auto border-2 border-gray-500 rounded-lg">
        {filteredNotifications.length > 0 ? (
          filteredNotifications.map((notification) => (
            <div
              key={notification.id}
              onClick={() => handleNotificationClick(notification)}
              className={`flex items-start p-4 mb-2 cursor-pointer rounded-lg hover:bg-gray-100 ${
                !notification.read ? "bg-gray-50" : ""
              }`}
            >
              <img
                src={
                  notification.senderAvatar
                    ? `${backendUrl}/images/${notification.senderAvatar}`
                    : defaultAvatar
                }
                alt={notification.senderName || "Sender"}
                className="w-12 h-12 rounded-full mr-4 object-cover"
              />
              <div>
                <div className="flex justify-between items-center">
                  <h4 className="text-sm font-semibold text-gray-800">
                    {notification.senderName || "Unknown Sender"}
                  </h4>
                  <span className="text-xs text-gray-500">
                    {new Date(notification.createdAt).toLocaleString()}
                  </span>
                </div>
                <p className="text-sm text-gray-600 truncate">
                  {notification.message}
                </p>
              </div>
            </div>
          ))
        ) : (
          <div className="flex items-center justify-center h-full text-gray-500">
            No notifications match your search.
          </div>
        )}
      </div>

      {/* Detail Modal */}
      <Modal
        isOpen={isDetailModalOpen}
        onRequestClose={() => setIsDetailModalOpen(false)}
        contentLabel="Notification Detail"
        className="max-w-2xl mx-auto mt-20 bg-white rounded-lg shadow-lg overflow-hidden"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-start justify-center p-4"
      >
        {selectedNotification && (
          <div className="relative p-6">
            <button
              onClick={() => setIsDetailModalOpen(false)}
              className="absolute top-4 right-4 text-gray-500 hover:text-red-500"
            >
              <CloseRoundedIcon />
            </button>
            <div className="flex items-start mb-4">
              <img
                src={
                  selectedNotification.senderAvatar
                    ? `${backendUrl}/images/${selectedNotification.senderAvatar}`
                    : defaultAvatar
                }
                alt={selectedNotification.senderName || "Sender"}
                className="w-16 h-16 rounded-full mr-4 object-cover"
              />
              <div>
                <h2 className="text-xl font-semibold text-gray-800">
                  {selectedNotification.senderName || "Unknown Sender"}
                </h2>
                <span className="text-xs text-gray-500">
                  {new Date(selectedNotification.createdAt).toLocaleString()}
                </span>
              </div>
            </div>
            <div>
              <p className="text-gray-700">{selectedNotification.message}</p>
              {selectedNotification.link && (
                <div className="mt-4">
                  <Link
                    to={selectedNotification.link}
                    className="text-green-600 hover:underline"
                  >
                    View More
                  </Link>
                </div>
              )}
            </div>
          </div>
        )}
      </Modal>
    </div>
    </>
  );
};

export default Notifications;
