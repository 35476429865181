import React, { useState, useEffect, useRef, memo } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Slider from "react-slick";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import SearchSharpIcon from "@mui/icons-material/SearchSharp";
import {
  FaHome,
  FaSmile,
  FaStar,
  FaDollarSign,
  FaRegSadCry,
  FaRulerCombined,
  FaShower,
  FaBed,
} from "react-icons/fa";
import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";

// Import Images
import house from "../assets/images/unsplash_mR1CIDduGLc.jpg";
import marketplace from "../assets/images/marketplace.svg";
import about from "../assets/images/about.svg";
import solution from "../assets/images/solution.svg";
import mission from "../assets/images/mission.svg";
import handshake from "../assets/images/handshake.svg";
import trusted from "../assets/images/trusted.svg";
import secure from "../assets/images/secure.svg";
import budgetFriendly from "../assets/images/budgetFriendly.svg";

// Import Components and Data
import testimonialsData from "../data/testimonials.json"; // Ensure this path is correct
import { MdGarage } from "react-icons/md";
import { useAuth } from "./AuthContext";

const PropertyCard = memo(function PropertyCard({ property, backendUrl2 }) {
  return (
    <Link
      to={`/propertyDetails/${property.id}`}
      className="block"
      aria-label={`View details of ${property.house_name}`}
    >
      <div className="bg-white shadow-md rounded-3xl overflow-hidden hover:shadow-lg transition-shadow duration-300">
        <div className="relative">
          <img
            src={`${backendUrl2}/${property.images[0]}`}
            alt={`Listing for ${property.tag}`}
            className="w-full h-60 object-cover"
            loading="lazy"
          />
          {/* Property Tag Badge */}
          <span className="absolute top-0 right-11 px-3 py-1 bg-green-700 text-white text-sm font-bold rounded-b-lg mt-5">
            {property.tag?.toUpperCase() || "TAG"}
          </span>
          {/* Property Price Badge */}
          <span className="absolute bottom-0 left-0 px-3 py-1 bg-green-700 text-white text-xl font-bold rounded-tr-lg">
            ₦{parseInt(property.price, 10).toLocaleString()}
          </span>
        </div>
        <div className="p-4">
          <h3 className="text-lg font-halant font-bold mb-2 text-gray-800">
            {property.house_name || "Unnamed Property"}
          </h3>
          <p className="text-gray-600 mb-1 font-halant-light">
            {property.state || "Unknown State"},
            {property.location || "Unknown Location"}
          </p>
          <div className="flex space-x-5 items-center mb-2">
            {/* Bedrooms */}
            <div className="flex flex-col-reverse sm:flex-row items-center text-sm text-gray-600 font-bold">
              <span className="sm:mr-1">{property.beds ?? 0}</span>
              <FaBed size={20} className="text-green-700" aria-hidden="true" />
            </div>
            {/* Bathrooms */}
            <div className="flex flex-col-reverse sm:flex-row items-center text-sm text-gray-600 font-bold">
              <span className="sm:mr-1">{property.baths ?? 0}</span>
              <FaShower
                size={20}
                className="text-green-700"
                aria-hidden="true"
              />
            </div>
            {/* Garages */}
            <div className="flex flex-col-reverse sm:flex-row items-center text-sm text-gray-600 font-bold">
              <span className="sm:mr-1">{property.garages ?? 0}</span>
              <MdGarage
                size={20}
                className="text-green-700"
                aria-hidden="true"
              />
            </div>
            {/* Land Area */}
            <div className="flex flex-col-reverse sm:flex-row items-center text-sm text-gray-600 font-bold">
              <span className="sm:mr-1">{property.land_area ?? 0} sqft</span>
              <FaRulerCombined
                size={20}
                className="text-green-700"
                aria-hidden="true"
              />
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
});

const statsData = [
  {
    id: 1,
    icon: <FaHome className="text-white w-8 h-8" />,
    value: 10000,
    label: "Property Units Sold",
    color: "bg-green-500",
  },
  {
    id: 2,
    icon: <FaSmile className="text-white w-8 h-8" />,
    value: 95,
    label: "Customer Satisfaction",
    color: "bg-blue-500",
    suffix: "%",
  },
  {
    id: 3,
    icon: <FaStar className="text-white w-8 h-8" />,
    value: 4.5,
    label: "Avg Customer Ratings",
    color: "bg-yellow-500",
    isStar: true,
    decimals: 1,
  },
  {
    id: 4,
    icon: <FaDollarSign className="text-white w-8 h-8" />,
    value: 100,
    label: "Total Property Volume",
    color: "bg-purple-500",
    suffix: "m",
  },
];


function Landing() {
  // Initialize Intersection Observer
  const [ref, inView] = useInView({
    triggerOnce: true, // Trigger only once
    threshold: 0.3, // 30% of the component should be visible
  });
  // Reference to the Slider component
  const sliderRef = useRef(null);

  // Slider settings with autoplay every 3 seconds and pause on hover
  const settings = {
    dots: true,
    infinite: true,
    speed: 700,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 3000, // 3 seconds
    pauseOnHover: true, // Pause autoplay on hover
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: "unslick", // Destroy the slider on larger screens
      },
    ],
  };

  // State variables for filters
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedPriceRange, setSelectedPriceRange] = useState("");
  const [selectedType, setSelectedType] = useState("");

  const [propertyData, setPropertyData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const backendUrl2 = process.env.REACT_APP_BACKEND_URL2;
  const { isLoggedIn } = useAuth();

  // **New Ref for Search Results**
  const searchResultsRef = useRef(null);

  // **New State Variable for Search Trigger**
  const [isSearched, setIsSearched] = useState(false);

  // **State Variable for Featured Properties**
  const [featuredProperties, setFeaturedProperties] = useState([]);

  // Fetch property data
  useEffect(() => {
    const fetchPropertyData = async () => {
      try {
        const response = await axios.get(`${backendUrl}/houses/`);
        setPropertyData(response.data);
        // **Extract Featured Properties**
        const featured = response.data.filter((property) => property.featured);
        setFeaturedProperties(featured);
      } catch (error) {
        console.error("Error fetching property data:", error);
        setError("Failed to load properties. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchPropertyData();
  }, [backendUrl]);

  // Effect to Reset `isSearched` When Inputs Are Cleared
  useEffect(() => {
    if (selectedLocation.trim() === "" && selectedPriceRange.trim() === "") {
      setIsSearched(false);
    }
  }, [selectedLocation, selectedPriceRange]);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <p className="text-gray-600 text-xl">Loading...</p>
      </div>
    );
  }
  // if (error) {
  //   return (
  //     <div className="flex flex-col items-center justify-center h-screen">
  //       <FaRegSadCry size={120} color="gray" aria-hidden="true" />
  //       <h2 className="text-gray-600 text-2xl mt-4">{error}</h2>
  //     </div>
  //   );
  // }

  // if (propertyData.length === 0) {
  //   return (
  //     <div className="flex flex-col items-center justify-center h-screen">
  //       <FaHome size={120} color="gray" aria-hidden="true" />
  //       <h2 className="text-gray-600 text-2xl mt-4">
  //         No Properties Available Yet
  //       </h2>
  //     </div>
  //   );
  // }

  // Filter properties based on selected filters
  const filteredProperties = propertyData.filter((property) => {
    // Filter by location (state)
    const trimmedLocation = selectedLocation.trim().toLowerCase();
    const locationMatch = trimmedLocation
      ? property.state?.toLowerCase().includes(trimmedLocation)
      : true;

    // Filter by price range
    let priceMatch = true;
    if (selectedPriceRange) {
      const price = parseFloat(property.price);
      if (selectedPriceRange === "100k to 200k") {
        priceMatch = price >= 100000 && price <= 200000;
      } else if (selectedPriceRange === "200k to 300k") {
        priceMatch = price >= 200000 && price <= 300000;
      }
    }

    // Filter by property type (tag)
    const typeMatch = selectedType ? property.tag === selectedType : true;

    // Return true if all conditions match
    return locationMatch && priceMatch && typeMatch;
  });

  // **Determine if Search Inputs are Provided**
  const isSearchInputProvided =
    selectedLocation.trim() !== "" ||
    selectedPriceRange.trim() !== "" ||
    selectedType.trim() !== "";

  return (
    <>
      <head>
        <title>HousemeNG | Welcome</title>
      </head>
      <div className="font-halant overflow-x-hidden">
        {/* Search Section */}
        <div className="border w-[100vw] h-[500px] sm:h-[718px] relative flex lg:justify-start items-center justify-center sm:mt-0">
          {/* Background Image */}
          <div className="w-[100%] h-[100%]">
            <img
              src={house}
              alt="House"
              className="w-[100%] h-[100%] object-cover brightness-50"
            />
          </div>
          <div className="absolute">
            {/* Welcome Text */}
            <div className="text-white text-center">
              <h1 className="sm:text-4xl text-2xl font-halant font-bold mt-20">
                Welcome to HOUSME.NG
              </h1>
              <p className="xxsm:text-lg text-base font-halant-light mb-5">
                Your premier destination for hassle-free real estate business
              </p>
            </div>

            {/* Conditionally render buttons based on login status */}
            {!isLoggedIn && (
              <div className="md:hidden space-x-4 mb-5 flex justify-center">
                {/* Sign Up Button */}
                <Link to="/signup">
                  <button className="bg-green-700 border-2 border-green-700 text-white font-bold font-halant py-2 px-7 rounded-3xl hover:bg-green-800">
                    Sign Up
                  </button>
                </Link>

                {/* Log In Button */}
                <Link to="/login">
                  <button className="border-2 border-green-700 text-white py-2 px-7 rounded-3xl font-bold font-halant hover:bg-green-100 hover:text-green-800">
                    Log In
                  </button>
                </Link>
              </div>
            )}

            {/* Search Functionality */}
            <div className="flex justify-center">
              <div className="mdx:bg-white rounded-full p-3 flex flex-col mdx:flex-row items-center space-y-3 mdx:space-y-0 mdx:space-x-4 lg:ml-[10%]">
                {/* First Two Inputs */}
                <div className="flex flex-row mb-3 mdx:mb-0 space-x-5 mdx:space-x-0">
                  <div className="relative flex items-center mb-2 mdx:mb-0 mdx:mr-3">
                    <LocationOnOutlinedIcon className="text-gray-400 absolute left-3" />
                    <input
                      type="text"
                      placeholder="Location"
                      onChange={(e) => setSelectedLocation(e.target.value)}
                      className="pl-8 px-4 py-2 border-t-2 border-b-[1px] bg-white text-black border-gray-300 w-40 h-11 font-halant-light text-base rounded-full focus:outline-none focus:ring-2 focus:ring-green-700 focus:border-transparent"
                    />
                  </div>
                  <select
                    value={selectedType}
                    onChange={(e) => setSelectedType(e.target.value)}
                    className="px-4 py-2 border-t-2 border-b-[1px] rounded-full bg-white text-black border-gray-300 w-40 h-11 font-halant-light text-base focus:outline-none focus:ring-2 focus:ring-green-700 focus:border-transparent"
                  >
                    <option value="">Property Type</option>
                    <option value="Sale">Sale</option>
                    <option value="Rent">Rent</option>
                    <option value="Shortlet">Shortlet</option>
                  </select>
                </div>

                {/* Second Two Inputs */}
                <div className="flex flex-row mb-3 mdx:mb-0 space-x-5 mdx:space-x-0">
                  <select
                    value={selectedPriceRange}
                    onChange={(e) => setSelectedPriceRange(e.target.value)}
                    className="px-4 py-2 border-t-2 border-b-[1px] rounded-full bg-white text-black border-gray-300 mb-2 sm:mb-0 sm:mr-3 w-40 h-11 font-halant-light text-base focus:outline-none focus:ring-2 focus:ring-green-700 focus:border-transparent"
                  >
                    <option value="">Price Range</option>
                    <option value="100k to 200k">100k to 200k</option>
                    <option value="200k to 300k">200k to 300k</option>
                  </select>
                </div>

                {/* Search Button */}
                <div>
                  <div className="flex">
                    <div className="relative">
                      <button
                        onClick={() => {
                          if (isSearchInputProvided) {
                            setIsSearched(true);
                            if (searchResultsRef.current) {
                              searchResultsRef.current.scrollIntoView({
                                behavior: "smooth",
                              });
                            }
                          }
                        }}
                        disabled={!isSearchInputProvided} // Disable if no input
                        className={`bg-green-700 text-white text-lg px-6 py-2 font-halant font-semibold rounded-3xl pr-10 flex items-center ${
                          !isSearchInputProvided
                            ? "opacity-50 cursor-not-allowed"
                            : "hover:bg-green-800"
                        }`}
                        aria-label="Search properties"
                      >
                        Search
                        <SearchSharpIcon className="text-white absolute right-3 top-1/2 transform -translate-y-1/2 mt-1" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Statistics Section */}
        <section className="bg-gradient-to-r from-green-200 to-gray-300 py-12">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            {/* Attach the ref to the container you want to observe */}
            <div
              ref={ref}
              className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8"
            >
              {statsData.map((stat) => (
                <div
                  key={stat.id}
                  className={`flex items-center p-6 rounded-lg shadow-lg ${stat.color} transform hover:scale-105 transition-transform duration-300`}
                >
                  <div className="flex-shrink-0">{stat.icon}</div>
                  <div className="ml-4">
                    <h3 className="text-3xl font-extrabold text-white">
                      {inView ? (
                        <CountUp
                          end={stat.value}
                          duration={2.5}
                          separator=","
                          decimals={stat.decimals || 0}
                          suffix={stat.suffix || ""}
                        />
                      ) : (
                        0
                      )}
                      {stat.isStar && (
                        <span className="text-yellow-300">★</span>
                      )}
                    </h3>
                    <p className="mt-2 text-lg leading-6 font-medium text-white">
                      {stat.label}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* **Search Output Section** */}
        <div ref={searchResultsRef} className="py-8 mx-7">
          {isSearched && (
            <>
              {filteredProperties.length > 0 ? (
                <div className="mb-8">
                  <h2 className="text-2xl font-halant font-bold mb-4">
                    We found something for you
                  </h2>
                  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                    {filteredProperties.map((property) => (
                      <PropertyCard
                        key={property.id}
                        property={property}
                        backendUrl2={backendUrl2}
                      />
                    ))}
                  </div>
                </div>
              ) : (
                <div className="flex flex-col items-center justify-center mb-8">
                  <FaRegSadCry size={120} color="gray" aria-hidden="true" />
                  <h2 className="text-gray-600 text-2xl mt-4">
                    We currently don't have what you're looking for, but you can
                    check these out:
                  </h2>
                </div>
              )}
            </>
          )}
        </div>

        {/* **Featured Properties Section** */}
        {/* <div className="py-8 mx-7">
          <div className="lg:mx-2 sm:mx-0 xsm:mx-[10%] mx-0">
            <h2 className="text-xl font-halant font-bold mb-1">
              Featured Properties
            </h2>
            <h2 className="text-lg font-halant-light mb-5">
              Premium listings highlighted for you
            </h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
              {featuredProperties.length > 0 ? (
                featuredProperties.map((property) => (
                  <PropertyCard
                    key={property.id}
                    property={property}
                    backendUrl2={backendUrl2}
                  />
                ))
              ) : (
                <p className="text-gray-500">
                  No featured properties available.
                </p>
              )}
            </div>
          </div>
        </div> */}

        {/* **Trending Properties Section** */}
        <div className="py-8 mx-7">
          <div className="lg:mx-2 sm:mx-0 xsm:mx-[10%] mx-0">
            <h2 className="text-4xl text-gray-700 font-halant font-bold mb-5">
              Featured Properties{" "}
            </h2>
            {/* <h2 className="text-lg font-halant-light mb-5">
              Based on top user searches in the last hour
            </h2> */}
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
              {isSearched
                ? propertyData
                    .filter(
                      (property) =>
                        !filteredProperties.find(
                          (fp) => fp.id === property.id
                        ) && !property.featured // Exclude filtered and featured properties
                    )
                    .map((property) => (
                      <PropertyCard
                        key={property.id}
                        property={property}
                        backendUrl2={backendUrl2}
                      />
                    ))
                : propertyData.map((property) => (
                    <PropertyCard
                      key={property.id}
                      property={property}
                      backendUrl2={backendUrl2}
                    />
                  ))}
            </div>
          </div>
        </div>

        {/* What We Offer */}
        <div className="bg-gradient-to-r from-gray-100 to-white py-20">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            {/* Section Header */}
            <h2 className="text-4xl font-extrabold text-gray-800 mb-12 text-center">
              What We Offer
            </h2>

            {/* Offer Cards Grid */}
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
              {/* Offer Card 1 */}
              <div className="relative bg-white rounded-2xl shadow-lg overflow-hidden transform hover:-translate-y-2 transition-transform duration-300">
                {/* Decorative Element */}
                <div className="absolute -top-4 -right-4 w-16 h-16 bg-green-200 rounded-full"></div>

                {/* Icon */}
                <div className="flex justify-center mt-6">
                  <img
                    src={trusted}
                    alt="Trusted by Thousands"
                    className="w-40"
                  />
                </div>

                {/* Content */}
                <div className="p-6 text-center">
                  <h3 className="text-2xl font-semibold text-gray-800 mb-4">
                    Trusted by Thousands
                  </h3>
                  <p className="text-gray-600">
                    We prioritize honesty & integrity in all our interactions,
                    ensuring that both buyers & sellers can trust the
                    information from property descriptions to transparent
                    pricing provided on our platform.
                  </p>
                </div>

                {/* Hover Overlay */}
                <div className="absolute inset-0 bg-green-500 bg-opacity-0 hover:bg-opacity-20 transition-opacity duration-300 flex items-center justify-center"></div>
              </div>

              {/* Offer Card 2 */}
              <div className="relative bg-white rounded-2xl shadow-lg overflow-hidden transform hover:-translate-y-2 transition-transform duration-300">
                {/* Decorative Element */}
                <div className="absolute -top-4 -right-4 w-16 h-16 bg-green-200 rounded-full"></div>

                {/* Icon */}
                <div className="flex justify-center mt-6">
                  <img
                    src={budgetFriendly}
                    alt="Budget Friendly"
                    className="w-40"
                  />
                </div>

                {/* Content */}
                <div className="p-6 text-center">
                  <h3 className="text-2xl font-semibold text-gray-800 mb-4">
                    Budget Friendly
                  </h3>
                  <p className="text-gray-600">
                    Our affordable pricing and low escrow fees ensure that you
                    keep more money in your pocket. Don't let high costs hold
                    you back from making the move of your dreams.
                  </p>
                </div>

                {/* Hover Overlay */}
                <div className="absolute inset-0 bg-green-500 bg-opacity-0 hover:bg-opacity-20 transition-opacity duration-300 flex items-center justify-center"></div>
              </div>

              {/* Offer Card 3 */}
              <div className="relative bg-white rounded-2xl shadow-lg overflow-hidden transform hover:-translate-y-2 transition-transform duration-300">
                {/* Decorative Element */}
                <div className="absolute -top-4 -right-4 w-16 h-16 bg-green-200 rounded-full"></div>

                {/* Icon */}
                <div className="flex justify-center mt-6">
                  <img
                    src={handshake}
                    alt="Hassle-Free Renting"
                    className="w-40"
                  />
                </div>

                {/* Content */}
                <div className="p-6 text-center">
                  <h3 className="text-2xl font-semibold text-gray-800 mb-4">
                    Hassle-Free Renting
                  </h3>
                  <p className="text-gray-600">
                    HouseMe simplifies the renting process, providing a
                    user-friendly platform for both buyers & sellers, ensuring a
                    seamless experience.
                  </p>
                </div>

                {/* Hover Overlay */}
                <div className="absolute inset-0 bg-green-500 bg-opacity-0 hover:bg-opacity-20 transition-opacity duration-300 flex items-center justify-center"></div>
              </div>

              {/* Offer Card 4 */}
              <div className="relative bg-white rounded-2xl shadow-lg overflow-hidden transform hover:-translate-y-2 transition-transform duration-300">
                {/* Decorative Element */}
                <div className="absolute -top-4 -right-4 w-16 h-16 bg-green-200 rounded-full"></div>

                {/* Icon */}
                <div className="flex justify-center mt-6">
                  <img
                    src={secure}
                    alt="Secure Escrow Service"
                    className="w-40"
                  />
                </div>

                {/* Content */}
                <div className="p-6 text-center">
                  <h3 className="text-2xl font-semibold text-gray-800 mb-4">
                    Secure Escrow Service
                  </h3>
                  <p className="text-gray-600">
                    With HouseMe's secure escrow service, you can ensure that
                    your money is protected until the deal is done. Rest easy
                    knowing that your transaction is in safe hands.
                  </p>
                </div>

                {/* Hover Overlay */}
                <div className="absolute inset-0 bg-green-500 bg-opacity-0 hover:bg-opacity-20 transition-opacity duration-300 flex items-center justify-center"></div>
              </div>

              {/* Offer Card 5 */}
              <div className="relative bg-white rounded-2xl shadow-lg overflow-hidden transform hover:-translate-y-2 transition-transform duration-300">
                {/* Decorative Element */}
                <div className="absolute -top-4 -right-4 w-16 h-16 bg-green-200 rounded-full"></div>

                {/* Icon */}
                <div className="flex justify-center mt-6">
                  <img
                    src={marketplace}
                    alt="Real Estate Marketplace"
                    className="w-40"
                  />
                </div>

                {/* Content */}
                <div className="p-6 text-center">
                  <h3 className="text-2xl font-semibold text-gray-800 mb-4">
                    Real Estate Marketplace
                  </h3>
                  <p className="text-gray-600">
                    HouseMe offers a diverse range of properties for sellers to
                    showcase to potential buyers, ensuring a wide selection for
                    all preferences.
                  </p>
                </div>

                {/* Hover Overlay */}
                <div className="absolute inset-0 bg-green-500 bg-opacity-0 hover:bg-opacity-20 transition-opacity duration-300 flex items-center justify-center"></div>
              </div>
            </div>
          </div>
        </div>

        {/* About Section */}
        <div className="bg-gradient-to-r from-green-100 to-white py-20">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 space-y-16">
            {/* ABOUT US */}
            <div className="flex flex-col md:flex-row items-center">
              {/* Text Content */}
              <div className="md:w-1/2 mr-3">
                <h3 className="text-3xl font-extrabold text-gray-800 mb-6">
                  ABOUT US
                </h3>
                <img src={about} alt="about-us" className="h-48 md:hidden" />
                <p className="text-lg text-gray-700 mb-6">
                  Welcome to Houseme, where we're revolutionizing the real
                  estate landscape with innovative solutions tailored to meet
                  your needs. At Houseme, we understand the challenges and
                  complexities that come with renting houses, whether you're a
                  seller looking to find the perfect buyer or a buyer seeking
                  the ideal property. That's why we've developed a cutting-edge
                  real estate marketplace website that brings together sellers
                  and buyers in a seamless, efficient manner.
                </p>
              </div>

              {/* Image */}
              <div className="md:w-1/2 mt-8 lg:mt-0 relative hidden md:flex">
                <img
                  src={about}
                  alt="About Us"
                  className="w-full h-auto rounded-lg object-cover"
                />
              </div>
            </div>

            {/* THE SOLUTION */}
            <div className="flex flex-col md:flex-row items-center">
              {/* Image */}
              <div className="md:w-1/2 mt-8 lg:mt-0 relative">
                <img
                  src={solution}
                  alt="The Solution"
                  className="w-full h-auto rounded-lg object-cover hidden md:flex"
                />
              </div>

              {/* Text Content */}
              <div className="md:w-1/2 ml-3">
                <div className="flex flex-col items-end">
                  <h3 className="text-3xl font-extrabold text-gray-800 mb-6">
                    THE SOLUTION
                  </h3>
                  <img
                    src={solution}
                    alt="the-solution"
                    className="h-48 md:hidden"
                  />
                </div>

                <p className="text-lg text-gray-700 mb-6">
                  Our solution is simple yet powerful: a platform where estate
                  agencies, house agents, and owners can showcase their
                  properties to potential buyers, who can browse through
                  listings, compare prices and locations, and receive expert
                  advice from our trusted correspondents. With Houseme, finding
                  your dream home or selling your property has never been
                  easier.
                </p>
              </div>
            </div>

            {/* THE MISSION */}
            <div className="flex flex-col md:flex-row items-center">
              {/* Text Content */}
              <div className="md:w-1/2 mr-3">
                <h3 className="text-3xl font-extrabold text-gray-800 mb-6">
                  THE MISSION
                </h3>
                <img
                  src={mission}
                  alt="the-mission"
                  className="h-48 md:hidden"
                />
                <p className="text-lg text-gray-700">
                  But our mission goes beyond just facilitating transactions. At
                  Houseme, we're driven by a core set of values that guide
                  everything we do. We're committed to making the rental process
                  as stress-free as possible, striving to become a 'household
                  name' synonymous with reliability, transparency, and
                  exceptional service. Whether you're a first-time buyer or a
                  seasoned 'seller,' you can trust Houseme to provide the
                  superior experience you need every step of the way.
                </p>
              </div>

              {/* Image */}
              <div className="md:w-1/2 mt-8 lg:mt-0 relative">
                <img
                  src={mission}
                  alt="The Mission"
                  className="w-full h-auto rounded-lg object-cover hidden md:flex"
                />
              </div>
            </div>
          </div>
        </div>

        {/* Revamped Testimonials Section */}
        <div className="bg-gradient-to-r from-green-50 to-white py-20">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            {/* Section Header */}
            <h2 className="text-4xl font-extrabold text-gray-800 mb-12 text-center">
              What Our Clients Say
            </h2>

            {/* Testimonials Slider for Mobile */}
            <div className="sm:hidden">
              <Slider {...settings} ref={sliderRef}>
                {testimonialsData.map((testimonial, index) => (
                  <div key={index} className="p-4">
                    <div className="bg-white p-8 rounded-2xl shadow-lg relative flex flex-col">
                      {/* Quote Icon */}
                      <svg
                        className="w-12 h-12 text-green-500 absolute top-4 left-4"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path d="M7.17 14.17a4 4 0 010-5.66l1.41-1.41a6 6 0 010 8.48l-1.41-1.41zm9.66 0a4 4 0 010-5.66l1.41-1.41a6 6 0 010 8.48l-1.41-1.41z" />
                      </svg>

                      {/* Testimonial Text */}
                      <p className="text-gray-600 italic mb-6 flex-grow">
                        {testimonial.text}
                      </p>

                      {/* Author Info */}
                      <div className="flex items-center">
                        <img
                          src={`https://via.placeholder.com/60?text=${testimonial.author
                            .split(" ")[0][0]
                            .toUpperCase()}${testimonial.author
                            .split(" ")[1][0]
                            .toUpperCase()}`}
                          alt={testimonial.author}
                          className="w-16 h-16 rounded-full border-2 border-green-500 mr-4 object-cover"
                        />
                        <div>
                          <h3 className="text-lg font-semibold text-gray-800">
                            {testimonial.author}
                          </h3>
                          <p className="text-green-600">{testimonial.role}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>

            {/* Testimonials Masonry Grid for Desktop */}
            <div className="hidden sm:block">
              <div className="columns-1 md:columns-2 lg:columns-3 gap-8">
                {testimonialsData.map((testimonial, index) => (
                  <div
                    key={index}
                    className="bg-white p-8 rounded-2xl shadow-lg mb-8 break-inside-avoid relative transform hover:scale-105 transition-transform duration-300"
                  >
                    {/* Quote Icon */}
                    <svg
                      className="w-12 h-12 text-green-500 absolute top-4 left-4"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path d="M7.17 14.17a4 4 0 010-5.66l1.41-1.41a6 6 0 010 8.48l-1.41-1.41zM16.83 14.17a4 4 0 010-5.66l1.41-1.41a6 6 0 010 8.48l-1.41-1.41z" />
                    </svg>

                    {/* Testimonial Text */}
                    <p className="text-gray-600 italic mb-6">
                      {testimonial.text}
                    </p>

                    {/* Author Info */}
                    <div className="flex items-center">
                      <img
                        src={`https://via.placeholder.com/60?text=${testimonial.author
                          .split(" ")[0][0]
                          .toUpperCase()}${testimonial.author
                          .split(" ")[1][0]
                          .toUpperCase()}`}
                        alt={testimonial.author}
                        className="w-16 h-16 rounded-full border-2 border-green-500 mr-4 object-cover"
                      />
                      <div>
                        <h3 className="text-lg font-semibold text-gray-800">
                          {testimonial.author}
                        </h3>
                        <p className="text-green-600">{testimonial.role}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* Call to Action */}
        <div className="bg-gradient-to-r from-green-500 to-green-700 sm:mx-40 mx-4 py-20 my-16 rounded-lg shadow-lg">
          <div className="max-w-3xl mx-auto text-center text-white">
            <h1 className="md:text-5xl text-3xl font-extrabold mb-6">
              Find Your Perfect Home with
            </h1>
            <h1 className="md:text-5xl text-3xl font-extrabold mb-8">
              Houseme.ng Today!
            </h1>
            <p className="text-lg mb-10 px-1">
              Discover a wide range of properties tailored to your needs.
              Whether you're buying or renting, Houseme.ng ensures a seamless
              experience.
            </p>
            <Link to="/signup">
              <button className="bg-white text-green-700 px-8 py-3 rounded-full font-semibold shadow-md hover:bg-gray-100 transition-colors duration-300 flex items-center justify-center">
                <span>Get Started</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 ml-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M17 8l4 4m0 0l-4 4m4-4H3"
                  />
                </svg>
              </button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default Landing;
