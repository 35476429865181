import { Link } from "react-router-dom";
import { FaBed, FaRegSadCry, FaRulerCombined, FaShower } from "react-icons/fa";
import { MdGarage } from "react-icons/md";

function Properties({ properties, origin }) {
  const backendUrl2 = process.env.REACT_APP_BACKEND_URL2;

  if (properties.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center h-screen">
        <FaRegSadCry size={120} color="gray" aria-hidden="true" />
        <h2 className="text-gray-600 text-2xl mt-4">
          Nothing to see here for now
        </h2>
      </div>
    );
  }

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-11 font-halant">
      {properties.map((property, index) => (
        <Link
          key={index}
          to={`/propertyDetails/${property.id}`}
          state={{ from: origin }}
        >
          <div className="py-3 w-full md:px-0 sm:px-[2%] xsm:px-[15%] px-[5.5%]">
            <div>
              <div className="">
                <div className="bg-white shadow-md rounded-3xl overflow-hidden hover:shadow-lg transition-shadow duration-300">
                  <div className="relative">
                    <img
                      src={`${backendUrl2}/${property.images[0]}`}
                      alt={`Listing for ${property.tag}`}
                      className="w-full h-60 object-cover"
                      loading="lazy"
                    />
                    {/* Property Tag Badge */}
                    <span className="absolute top-0 right-11 px-3 py-1 bg-green-700 text-white text-sm font-bold rounded-b-lg mt-5">
                      {property.tag?.toUpperCase() || "TAG"}
                    </span>
                    {/* Property Price Badge */}
                    <span className="absolute bottom-0 left-0 px-3 py-1 bg-green-700 text-white text-xl font-bold rounded-tr-lg">
                      ₦{parseInt(property.price, 10).toLocaleString()}
                    </span>
                  </div>
                  <div className="p-4">
                    <h3 className="text-lg font-halant font-bold mb-2 text-gray-800">
                      {property.house_name || "Unnamed Property"}
                    </h3>
                    <p className="text-gray-600 mb-1 font-halant-light">
                      {property.state || "Unknown State"},
                      {property.location || "Unknown Location"}
                    </p>
                    <div className="flex space-x-5 items-center mb-2">
                      {/* Bedrooms */}
                      {property.beds > 0 && (
                        <div className="flex flex-col-reverse items-center text-sm text-gray-600 font-bold">
                          <span className="mr-1">{property.beds}</span>
                          <FaBed
                            size={20}
                            className="text-green-700"
                            aria-hidden="true"
                          />
                        </div>
                      )}
                      {/* Bathrooms */}
                      {property.baths > 0 && (
                        <div className="flex flex-col-reverse items-center text-sm text-gray-600 font-bold">
                          <span className="mr-1">{property.baths}</span>
                          <FaShower
                            size={20}
                            className="text-green-700"
                            aria-hidden="true"
                          />
                        </div>
                      )}
                      {/* Garages */}
                      {property.garages > 0 && (
                        <div className="flex flex-col-reverse items-center text-sm text-gray-600 font-bold">
                          <span className="mr-1">{property.garages}</span>
                          <MdGarage
                            size={20}
                            className="text-green-700"
                            aria-hidden="true"
                          />
                        </div>
                      )}
                      {/* Land Area */}
                      {property.land_area > 0 && (
                        <div className="flex flex-col-reverse items-center text-sm text-gray-600 font-bold">
                          <span className="mr-1">
                            {property.land_area} sqft
                          </span>
                          <FaRulerCombined
                            size={20}
                            className="text-green-700"
                            aria-hidden="true"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Link>
      ))}
    </div>
  );
}

export default Properties;
