// src/components/FundWalletModal.js

import React, { useState, useEffect, useCallback } from "react";
import Modal from "react-modal";
import axios from "axios";
import { PaystackButton } from "react-paystack";
import { toast } from "react-toastify";

// Ensure Modal is attached to the app root for accessibility
Modal.setAppElement("#root");

const FundWalletModal = ({ isOpen, onClose }) => {
  const [amountToFund, setAmountToFund] = useState("");
  const [balance, setBalance] = useState(0.0);
  const [email, setEmail] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);

  // Retrieve backendUrl and publicKey from environment variables
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const publicKey = process.env.REACT_APP_PAYSTACK_PUBLIC_KEY;

  // Fetch wallet balance
  const fetchBalance = useCallback(async () => {
    const storedUserId = localStorage.getItem("userId");
    if (!storedUserId) {
      toast.error("User not authenticated. Please log in.");
      return;
    }

    try {
      const walletResponse = await axios.get(
        `${backendUrl}/chats/wallet/${storedUserId}`
      );
      setBalance(walletResponse.data.balance);
    } catch (error) {
      console.error("Error fetching wallet balance:", error);
      toast.error("Failed to retrieve wallet balance.");
    }
  }, [backendUrl]);

  useEffect(() => {
    if (isOpen) {
      // Fetch email and balance when modal opens
      const storedEmail = localStorage.getItem("email") || "example@gmail.com";
      setEmail(storedEmail);
      fetchBalance();
    } else {
      // Reset state when modal closes
      setAmountToFund("");
    }
  }, [isOpen, fetchBalance]);

  // Paystack configuration
  const config = {
    reference: new Date().getTime().toString(),
    email: email,
    amount: parseInt(amountToFund) * 100, // Paystack expects amount in kobo
    publicKey: publicKey,
  };

  // Handle Paystack payment success
  const handleSuccess = async (reference) => {
    setIsProcessing(true);
    const storedUserId = localStorage.getItem("userId");

    if (!storedUserId) {
      toast.error("User not authenticated. Please log in.");
      setIsProcessing(false);
      return;
    }

    try {
      const response = await axios.post(`${backendUrl}/users/update-wallet`, {
        userId: storedUserId,
        amount: parseFloat(amountToFund),
        reference: reference.reference,
      });

      if (response.status === 200) {
        toast.success("Wallet funded successfully!");
        setBalance(response.data.balance);
        setAmountToFund("");
        onClose();
        // Optionally, you can emit an event or use a global state to notify other components
      } else {
        toast.error("Failed to fund wallet. Please try again.");
      }
    } catch (error) {
      console.error("Error funding wallet:", error);
      const errorMessage =
        error.response?.data?.message ||
        "Failed to fund wallet. Please try again.";
      toast.error(errorMessage);
    } finally {
      setIsProcessing(false);
    }
  };

  // Handle Paystack payment close
  const handleClose = () => {
    if (!isProcessing) {
      onClose();
    }
  };

  const componentProps = {
    ...config,
    text: "Fund Wallet",
    onSuccess: handleSuccess,
    onClose: handleClose,
    className:
      "mt-4 bg-green-500 hover:bg-green-700 text-white py-2 px-4 rounded-lg w-full",
    disabled: !amountToFund || isProcessing,
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      contentLabel="Fund Wallet"
      className="absolute z-20 inset-0 flex items-center justify-center bg-black bg-opacity-75"
      overlayClassName="fixed inset-0 bg-black bg-opacity-75"
      ariaHideApp={false} // Already set via Modal.setAppElement in the component
    >
      <div className="relative bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg w-[90%] max-w-md mx-auto">
        <button
          onClick={handleClose}
          className="absolute top-3 right-3 text-gray-500 hover:text-gray-700 dark:hover:text-gray-300 focus:outline-none"
          aria-label="Close Fund Wallet Modal"
        >
          &times;
        </button>
        <h2 className="text-xl font-bold mb-4 text-gray-800 dark:text-white">
          Fund Your Wallet
        </h2>
        <div className="mb-4">
          <h3 className="text-gray-700 text-lg">Current Balance:</h3>
          <p className="text-3xl font-semibold text-green-700 dark:text-green-400">
            ₦
            {balance !== undefined && balance !== null
              ? balance.toFixed(2)
              : "0.00"}
          </p>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Enter Amount to Fund:
          </label>
          <input
            type="number"
            value={amountToFund}
            onChange={(e) => setAmountToFund(e.target.value)}
            className="border rounded-lg p-2 w-full dark:bg-gray-700 dark:border-gray-600 dark:text-gray-200"
            placeholder="Enter amount"
            min="1"
          />
        </div>
        <PaystackButton {...componentProps} />
        <button
          onClick={handleClose}
          className="mt-4 bg-gray-500 hover:bg-gray-600 text-white py-2 px-4 rounded-lg w-full"
        >
          Close
        </button>
      </div>
    </Modal>
  );
};

export default FundWalletModal;
